import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    IconButton,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    Autocomplete,
    Popper,
    FormControlLabel,
    Radio,
    RadioGroup,
    DialogActions,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

function EditAccountCardModal(props) {
    const {
        open,
        onClose,
        rowData,
        isNew,
        onSave,
        palCodesOptions,
        sourceDeductionOptions,
        direction,
        t,
        toast,
    } = props;

    const [editedData, setEditedData] = useState({ ...rowData });

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    const handleChange = (field, value) => {
        setEditedData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSave = () => {
        let isValid = true;
        
        if (editedData.email!== '' && !validateEmail(editedData.email)) {
            toast.error(t('InvalidEmailAddress'));
            isValid = false;
        }
        if (editedData.phoneNumber!== '' && !validateNumber(editedData.phoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            isValid = false;
        }

        if (isValid) {
            onSave(editedData, isNew);
            onClose();
        }
    };
    const validateEmail = (email) => {
        // Simple email validation
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validateNumber = (number) => {
        const re = /^[0-9\b]+$/;
        return re.test(number);
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle dir={`${direction}`}>
                {isNew ? t('AddAccountCard') : t('EditAccountCard')}
            </DialogTitle>
            <DialogContent dividers>
                {/* Fields for editing */}
                <div className={`form-container ${direction}`}>
                    {/* Row 1 */}
                    <div className="form-row">
                        {/* Account Card ID */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('AccountCard ID')}
                                <span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                value={editedData.cardId || ''}
                                onChange={(e) => handleChange('cardId', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                        {/* Account Card Name */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('AccountCard Name')}
                                <span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                value={editedData.name || ''}
                                onChange={(e) => handleChange('name', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                    </div>
                    {/* Row 2 */}
                    <div className="form-row">
                        {/* PalCode */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('PalCode')}
                            </div>
                            <Autocomplete
                                options={palCodesOptions}
                                getOptionLabel={(option) => option.label}
                                value={
                                    palCodesOptions.find((option) => option.value === editedData.palCode) || null
                                }
                                onChange={(event, newValue) => {
                                    handleChange('palCode', newValue ? newValue.value : null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            ...inputPropsStyle,
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            ...inputStyle,
                                        }}
                                    />
                                )}
                                PopperComponent={(props) => (
                                    <Popper
                                        {...props}
                                        style={{
                                            ...props.style,
                                            direction: direction,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        {/* Source Deduction Association */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('SourceDeductionAssociation')}
                            </div>
                            <Autocomplete
                                options={sourceDeductionOptions}
                                getOptionLabel={(option) => option.label}
                                value={
                                    sourceDeductionOptions.find((option) => option.value === editedData.sourceDeductionAssociation) || null
                                }
                                onChange={(event, newValue) => {
                                    handleChange('sourceDeductionAssociation', newValue ? newValue.value : null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            ...inputPropsStyle,
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            ...inputStyle,
                                        }}
                                    />
                                )}
                                PopperComponent={(props) => (
                                    <Popper
                                        {...props}
                                        style={{
                                            ...props.style,
                                            direction: direction,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    {/* Row 3 */}
                    <div className="form-row">
                        {/* Email */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('Email')}
                            </div>
                            <TextField
                                fullWidth
                                value={editedData.email || ''}
                                onChange={(e) => handleChange('email', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                type="email"
                            />
                        </div>
                        {/* Location */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('Location')}
                            </div>
                            <TextField
                                fullWidth
                                value={editedData.location || ''}
                                onChange={(e) => handleChange('location', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                    </div>
                    {/* Row 4 */}
                    <div className="form-row">
                        {/* Phone Number */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('PhoneNumber')}
                            </div>
                            <TextField
                                fullWidth
                                value={editedData.phoneNumber || ''}
                                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                type="tel"
                            />
                        </div>
                        {/* Account Case ID */}
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title">
                                {t('CompanyId')}
                            </div>
                            <TextField
                                fullWidth
                                value={editedData.accountCaseId || ''}
                                onChange={(e) => handleChange('accountCaseId', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions dir={`${direction}`}>
                <Button onClick={onClose} startIcon={<CancelIcon />}>
                    {t('Cancel')}
                </Button>
                <Button onClick={handleSave} startIcon={<SaveIcon />} color="primary">
                    {t('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditAccountCardModal;
