// JournalEntryDraggableDialog.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    TextField,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    Portal,
    Popper,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';

function JournalEntryDraggableDialog(props) {
    const { t, i18n } = useTranslation();
    const {
        open,
        onClose,
        data,
        onSave,
        accountCardOptions,
        creditAccountOptions,
        debitAccountOptions,
        sortCodeIdOptions,
        Constants,
        direction,
        isAddMode, // true for adding, false for editing
        isClosedReportingMonth
    } = props;
    const [formData, setFormData] = useState(data || {});
    const [container] = useState(() => document.createElement('div'));
    const nodeRef = useRef(null);

    // VAT options
    const vatOptions = [
        { value: 100, label: '100%' },
        { value: 66, label: '66%' },
        { value: 0, label: '0%' },
        { value: 16, label: t('AccountingTax') },
    ];

    // For VAT checkbox selection
    const [selectedVatValue, setSelectedVatValue] = useState(formData.vat || '');

    useEffect(() => {
        setFormData(data || {});
        setSelectedVatValue(data?.vat || '');
    }, [data]);

    useEffect(() => {
        if (open) {
            document.body.appendChild(container);

            // Center the modal
            const updateModalPosition = () => {
                const modalWidth = 700; // Adjust as needed
                const modalHeight = nodeRef.current ? nodeRef.current.offsetHeight : 0;
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;

                const x = (screenWidth - modalWidth) / 2;
                const y = (screenHeight - modalHeight) / 2;

                nodeRef.current.style.left = `${x}px`;
                nodeRef.current.style.top = `${y}px`;
            };

            // Update position after the modal has rendered
            setTimeout(updateModalPosition, 0);

            // Update position on window resize
            window.addEventListener('resize', updateModalPosition);

            return () => {
                try {
                    document.body.removeChild(container);
                } catch (e) {
                    // Ignore
                }
                window.removeEventListener('resize', updateModalPosition);
            };
        } else {
            try {
                document.body.removeChild(container);
            } catch (e) {
                // Ignore
            }
        }
    }, [open, container]);
    useEffect(() => {
        setSelectedVatValue(formData.vat)
    }, [formData.vat]);

    const handleChange = (field, value) => {
        const updatedData = { ...formData, [field]: value };



        if (field === 'sortCodeId') {
            const selectedOption = sortCodeIdOptions.find(
                (option) => option.sortCodeId === value
            );

            if (selectedOption) {
                const nameWithoutParentheses = selectedOption.sortCodeName
                    .replace(/\s*\(.*?\)\s*/, '')
                    .trim();

                const match = selectedOption.sortCodeName.match(/\((\d+)%?/);
                let customVat = match ? parseInt(match[1], 10) : null;

                const sortCode = selectedOption;

                if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
                    updatedData.vat = 66;
                } else if (
                    Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) ||
                    sortCode.sortCodeVatType === 0
                ) {
                    updatedData.vat = 0;
                } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
                    updatedData.vat = 16;
                } else if (customVat !== null) {
                    updatedData.vat = customVat === 66 ? 66 : customVat;
                } else {
                    updatedData.vat = 100;
                }


            }
        }


        setFormData(updatedData);
    };
    const handleVatChange = (value) => {
        setSelectedVatValue(value);
        handleChange('vat', value);
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    // Input style as per your code snippet
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'
            }`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    if (!open) return null;

    return (
        <Portal container={container}>
            <Draggable handle=".handle" nodeRef={nodeRef}>
                <div
                    ref={nodeRef}
                    style={{
                        position: 'absolute',
                        top: '20%',
                        left: '30%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        minWidth: '700px',
                        zIndex: 1300,
                    }}
                >
                    <div
                        className="handle"
                        style={{
                            cursor: 'move',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginBottom: '16px',
                            width: '100%',
                        }}
                    >
                        <div className="edit-title">
                            {isAddMode ? t('AddJournalEntry') : t('EditJournalEntry')}
                        </div>
                    </div>
                    {/* Modal Content */}
                    <div className={`form-container ${direction}`}>
                        {/* Row 1 */}
                        <div className="form-row">
                            {/* Confirmation Number */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('ConfirmationNumber')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('ConfirmationNumber')}
                                    value={formData.confirmationNumber || ''}
                                    onChange={(e) => handleChange('confirmationNumber', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                    disabled={ isClosedReportingMonth}

                                />
                            </div>
                            {/* Account Case ID */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('CompanyId')}
                                    {isAddMode && <span className="required-asterisk">*</span>}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('CompanyId')}
                                    value={formData.accountCaseId || ''}
                                    onChange={(e) => handleChange('accountCaseId', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    disabled={ isClosedReportingMonth}

                                // required={isAddMode}
                                // disabled={!isAddMode}
                                />
                            </div>
                        </div>
                        {/* Row 2 */}
                        <div className="form-row">
                            {/* Reference */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Reference')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Reference')}
                                    value={formData.reference || ''}
                                    onChange={(e) => handleChange('reference', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                    disabled={ isClosedReportingMonth}

                                />
                            </div>
                            {/* Description */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Description')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Description')}
                                    value={formData.description || ''}
                                    onChange={(e) => handleChange('description', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                    disabled={ isClosedReportingMonth}

                                />
                            </div>
                        </div>
                        {/* Row 3 */}
                        <div className="form-row">
                            {/* Document Date */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Document Date')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                {/* Use CustomDatePicker here */}
                                <CustomDatePicker
                                    date={
                                        formData.documentDate ? new Date(formData.documentDate) : null
                                    }
                                    onDateChange={(date) => {
                                        if (date) {
                                            handleChange('documentDate', date.toISOString());
                                        }
                                    }}
                                    height="48px"
                                />
                            </div>
                            {/* Amount */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Amount')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Amount')}
                                    value={formData.amount || ''}
                                    onChange={(e) => handleChange('amount', e.target.value)}
                                    variant="standard"
                                    type="number"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                    disabled={ isClosedReportingMonth}
                                />
                            </div>
                        </div>
                        {/* Row 4 */}
                        <div className="form-row">
                            {/* VAT */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('VATPercentage')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {vatOptions.map((option) => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    checked={selectedVatValue == option.value}
                                                    onChange={() => handleVatChange(option.value)}
                                                    name={`vat-${option.value}`}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: 14 }}>{option.label}</Typography>
                                            }
                                            disabled={ isClosedReportingMonth}
                                        />
                                    ))}
                                </div>
                            </div>
                            {/* Sort Code ID */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Code ID')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={sortCodeIdOptions}
                                    getOptionLabel={(option) =>
                                        `${option.sortCodeId} - ${option.sortCodeName}`
                                    }
                                    value={
                                        sortCodeIdOptions.find(
                                            (option) => option.sortCodeId === formData.sortCodeId
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newSortCodeId = newValue ? newValue.sortCodeId : '';
                                        handleChange('sortCodeId', newSortCodeId);
                                    }}

                                    disabled={ isClosedReportingMonth}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Code ID')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {/* Row 5 - only in edit mode */}

                        <div className="form-row">
                            {/* Credit Account */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Credit Account')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={creditAccountOptions}
                                    getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                                    value={
                                        creditAccountOptions.find(
                                            (option) => option.cardId === formData.creditAccount
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newCreditAccount = newValue ? newValue.cardId : null;
                                        handleChange('creditAccount', newCreditAccount);
                                    }}
                                    disabled={ isClosedReportingMonth}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Credit Account')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {/* Debit Account */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Debit Account')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <Autocomplete
                                    options={debitAccountOptions}
                                    getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                                    disabled={ isClosedReportingMonth}
                                    value={
                                        debitAccountOptions.find(
                                            (option) => option.cardId === formData.debitAccount
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        const newDebitAccount = newValue ? newValue.cardId : null;
                                        handleChange('debitAccount', newDebitAccount);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('Debit Account')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                            required
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {/* Save and Cancel Buttons */}
                        <div className="button-container">
                            <div onClick={onClose} className="edit-cancel-button">
                                {t('Cancel')}
                            </div>
                            <div
                                onClick={isClosedReportingMonth ? null : handleSave}
                                className="edit-save-button"
                                style={{ opacity: isClosedReportingMonth ? 0.5 : 1 }}
                            >
                                {t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </Portal>
    );
}

export default JournalEntryDraggableDialog;
