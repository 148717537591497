import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select, { components } from 'react-select';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import DocumentService from '../../Services/documents.service';
import UserService from '../../Services/user.service';
import './PaymentAcceptance.css';
import backIcon from '../../assests/images/left-arrow.png';
import SignaturePad from 'react-signature-canvas';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "../../assests/images/downloadIcon.png";
import { useSignalR } from '../../Contexts/signalR';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service';
import Modal from '@mui/material/Modal';
import Header from '../Header/Header';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import PuffLoader from "react-spinners/PuffLoader";

import Iframe from 'react-iframe';
import ClientService from '../../Services/client.service';
import loadingAnimation from "../../assests/images/Animation.gif";
import CustomDropdown from '../CustomDropdown/CustomDropdown';
// Table-related imports
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import NoDocumentScanned from '../../assests/images/svgs/NoDocumentScanned.svg';
import bankIcon from "../../assests/images/bank-icon.png";
import creditCardIcon from "../../assests/images/credit-card-icon.png";
import checkIcon from "../../assests/images/check-icon.png";
import cashIcon from "../../assests/images/cash-icon.png";
import CloseIcon from '@mui/icons-material/Close';
import { Edit as EditIcon, Refresh, Delete as DeleteIcon, Download as DownloadIconMui, Search as SearchIcon, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import Constants from '../../assests/Constants/constants';
// Create-related imports
import addIcon from "../../assests/images/plusO.png";
import cancelIcon from "../../assests/images/cancel.png";
import calendarIcon from '../../assests/images/calendar.png';
import DraggableModal from '../DraggableModal/DraggableModal';
import dayjs, { Dayjs } from 'dayjs';
import { beautifyNumber } from '../../Utils/FormatNumber';
import DateInput from '../DateInput/DateInput';
import CustomMonthDatePicker from '../CustomMonthDatePicker/CustomMonthDatePicker';
import DenseTable from '../DenseTable/DenseTable';
import DocumentTransferDialog from '../DocumentTransferDialog/DocumentTransferDialog';
import styled from '@mui/system/styled';
import {
    Button, Dialog, DialogTitle, DialogContent, Box, DialogActions, Typography, TextField, Checkbox,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    ToggleButton, ToggleButtonGroup, Switch, CircularProgress,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import PaymentAcceptanceDataGrid from './SubComponents/PaymentAcceptanceDataGrid';
import TablePagination from '@mui/material/TablePagination';
import PaymentAcceptanceTable from './SubComponents/PaymentAcceptanceTable';
import { height } from '@mui/system';
import PaymentAcceptanceCreateEditModal from './SubComponents/PaymentAcceptanceCreateEditModal';
import { FileContext } from '../../Contexts/FileContext';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it
const formatDate = dateString => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};


const customStyles = {
    valueContainer: (base, state) => ({
        ...base,
        justifyContent: "center"
    }),
}

const StyledDialogTitle = styled(DialogTitle)({
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#025366',
});

const StyledDialogContent = styled(DialogContent)({
    textAlign: 'center',
    padding: '20px 30px',
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: 'center',
    paddingBottom: '20px',
});

const StyledButton = styled(Button)({
    margin: '0 8px',
    padding: '10px 20px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#E57C22',
    '&:hover': {
        backgroundColor: '#d9731a',
    },
});

const OptionButton = styled(Button)({
    margin: '10px 8px',
    padding: '12px 25px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#025366',
    backgroundColor: '#f5f5f5',
    border: '1px solid #E57C22',
    width: 'auto',  // Allows the button to shrink when there are many buttons
    maxWidth: '200px',  // Set a maximum width to ensure consistency
    flexGrow: 1,  // Allows buttons to fill available space equally
    '&:hover': {
        backgroundColor: '#E57C22',
        color: '#fff',
    },
});

const ActionButton = styled(Button)({
    backgroundColor: '#304FFF', // Consistent background with other elements
    borderRadius: 4, // Same border-radius as MUI TextField and Switch
    color: 'white',
    height: '55px', // Same height as other input elements
    padding: '0 16px', // Consistent padding
    fontSize: '0.875rem', // Matching font size with TextField
    textTransform: 'none',
    boxSizing: 'border-box',
    '&:hover': {
        backgroundColor: '#F9AA2A', // Slightly darker on hover
    },
    '&:disabled': {
        backgroundColor: '#E0E0E0',
        color: '#BDBDBD',
    },
});

function PaymentAcceptance() {
    const VAT_RATE = 0.17;
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const direction = i18n.dir();
    const { setFiles } = useContext(FileContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [receiptTaxModalVisible, setReceiptTaxModalVisible] = useState(false);
    const [checkTypeOption, setCheckTypeOption] = useState('');
    const [documentReference, setDocumentReference] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [oldSelectedAccountCardItem, setOldSelectedAccountCardItem] = useState({
        cardId: "",
        caseId: "",
        accountCaseId: "",
        name: "",
        palCode: '',
        client: null
    });
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [okMessage, setOkMessage] = useState('');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const modalCreateDocumentRef = useRef(); // Reference to the modal content
    const [checkTypeOptions, setCheckTypeOptions] = useState([]);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [editAccountCardOptions, setEditAccountCardOptions] = useState([]);
    const [accountCardMap, setAccountCardMap] = useState({});
    const location = useLocation();
    const [editingRow, setEditingRow] = useState(null);
    const [editedItem, setEditedItem] = useState({});
    const [editedAccountCard, setEditedAccountCard] = useState(false);
    const [originalAccountCardId, setOriginalAccountCardId] = useState('');
    const [sortDate, setSortDate] = useState(() => {
        const storedDateString = localStorage.getItem('Check_Date');
        return storedDateString ? new Date(storedDateString) : new Date();
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const [statusCheck, setStatusCheck] = useState(false);
    const [showType, setShowType] = useState({ type: 'all', index: -1 });
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100); // You can set any default value
    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem('Check_StartDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // Start date for range
    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem('Check_EndDate');
        return storedDateString ? new Date(storedDateString) : startDate;
    });;; // End date for range
    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('Check_IsRange');
        return storedIsRange === "true";
    });;// Flag to toggle between single date and range
    const [focused, setFocused] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);


    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadStartDate, setDownloadStartDate] = useState(null); // Start date for range
    const [downloadEndDate, setDownloadEndDate] = useState(null);
    const [downloadAmount, setDownloadAmount] = useState();
    const [downloadAccountCodeId, setDownloadAccountCodeId] = useState([]);
    const [downloadCheckType, setDownloadCheckType] = useState('');
    const { documentUpdate } = useSignalR();
    const [shouldDelete, setShouldDelete] = useState(false);
    const [deleteDto, setDeleteDto] = useState({});
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [actionType, setActionType] = useState('delete');
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const tableContainerRef = useRef();
    const customScrollbarRef = useRef();
    const [denseTableHeader, setDenseTableHeader] = useState([]);
    const [denseTableRows, setDenseTableRows] = useState([]);
    const [acceptedWithholdingTax, setAcceptedWithholdingTax] = useState(0);
    const [showAccountCardInputsEdit, setShowAccountCardInputsEdit] = useState(false);
    const [palCode, setPalCode] = useState('');
    const [paymentWithholdingTax, setPaymentWithholdingTax] = useState(0);
    const [accountCardIdToDelete, setAccountCardIdToDelete] = useState('');
    const [denseTableCreditDebitHeader, setDenseTableCreditDebitHeader] = useState([]);
    const [denseTableCreditDebitRows, setDenseTableCreditDebitRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [cardIdOption, setCardIdOption] = useState(-1);

    useEffect(() => {

        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(...accountCardOptions.slice(1).map(option => parseInt(option.cardId, 10)));
            setCardIdOption(maxCardId + 1);


        }
    }, [accountCardOptions]);

    const filteredAndSortedData = useMemo(() => {
        if (data.length < 0) {
            return;
        }
        const sortedData = data.filter((item) => {
            const query = searchQuery.toLowerCase();
            const amountString = item?.amount?.toString(); // Safely access item.amount
            return (
                item?.accountCodeName?.toLowerCase()?.includes(query) || // Safely access item.title
                amountString?.includes(query) ||
                formatDate(item?.date)?.includes(query) ||
                item?.reference?.includes(query) ||
                item?.description?.includes(query) ||
                item?.uploadNumber?.toString()?.includes(query)
            );
        });
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (sortConfig.type === 'newest') {
                    const highlightedIds = client.documentIds.checks;
                    const aIsHighlighted = highlightedIds.includes(a.id);


                    const bIsHighlighted = highlightedIds.includes(b.id);

                    if (sortConfig.direction === 'ascending') {
                        // Ascending: Non-highlighted (older) items first
                        if (!aIsHighlighted && bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is older, hence "lower")
                        } else if (aIsHighlighted && !bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is older, hence "lower")
                        }
                    } else {
                        // Descending: Highlighted (newer) items first
                        if (aIsHighlighted && !bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is newer, hence "higher")
                        } else if (!aIsHighlighted && bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is newer, hence "higher")
                        }
                    }
                } else {
                    const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                    const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined
                    if (sortConfig.type === 'number') {
                        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                    } else if (sortConfig.type === 'date') {
                        return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
                    } else { // default to string comparison
                        return sortConfig.direction === 'ascending' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
                    }
                }
            });
        }

        return sortedData;
    }, [data, searchQuery, sortConfig]);

    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const handleTableScroll = (e) => {
        if (customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const synchronizeScroll = () => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = tableContainerRef.current.scrollLeft;

        }
    };



    useEffect(() => {
        synchronizeScroll();
    }, [filteredAndSortedData]);

    const setOpenOption = () => setOpenOptions(true);
    const closeOptions = () => {
        setOpenOptions(false);
        setSelectedRows([]); // Unselect all rows
        setIsAllSelected(false); // Uncheck the "Select all" checkbox
    };
    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleSelectRow = (event, item) => {

        const selectedIndex = selectedRows.findIndex(selected => selected.id === item.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }
        setSelectedRows(newSelected);
    };


    const handleSelectAllClick = (event) => {
        const checked = event.target.checked;
        setIsAllSelected(checked);

        if (checked) {
            const newSelecteds = filteredAndSortedData.map((item) => item.id);
            setSelectedRows(newSelecteds);
            setAllSelected(true);
        } else {
            setSelectedRows([]);
            setAllSelected(false);
        }
    };

    const handleOptionSelect = async (option) => {

        if (selectedRows && selectedRows.length > 0) {
            if (option === 'done') {
                await handleDoneIdsClick(selectedRows);
            } else if (option === 'notDone') {
                await handleNotDoneIdsClick(selectedRows);
            }
        }

        closeOptions();
    };

    const handleDoneIdsClick = async (itemIds) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentsByTypeAndIds(client.caseId, client.accountantId, "Checks", itemIds, token);
            if (response.ok) {
                await fetchDocumentsNotification(client, token);
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentsNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneIdsClick = async (itemIds) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentsByTypeAndIds(client.caseId, client.accountantId, "Checks", itemIds, token);
            if (response.ok) {
                await fetchDocumentsNotification(client, token);
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentsNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    useEffect(() => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.firstChild.style.width = `${tableContainerRef.current.scrollWidth}px`;
        }
    }, [filteredAndSortedData, tableContainerRef.current?.scrollWidth]);

    const handleRadioChange = (e) => {
        if (e.target.value?.toString() === 'done') {

            setStatusCheck(true);
        }
        else if (e.target.value?.toString() === 'notDone') {
            setStatusCheck(false);
        }
        setAddEditableItem({ ...addEditableItem, ["status"]: e.target.value });
    };
    const handleDeleteClick = (itemId) => {
        setActionType('delete');
        setItemIdToDelete(itemId);
        setIsModalVisible(true);
    };

    const handleDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, "Checks", itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, "Checks", itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleSelectRows = (ids) => {
        if (ids && ids.length === 0) {
            setSelectedRows([]);
            return
        }
        let newSelected = [...selectedRows]; // Start with the current selected IDs

        ids.forEach((id) => {
            // Find the item in filteredAndSortedData that matches the selected id
            const item = filteredAndSortedData.find((row) => row.id === id);
            if (!item) return; // Skip if the item with the given id isn't found

            // Apply the same condition used for displaying checkboxes
            // const condition = client.accountManagement === Constants.AccountManagementOptions[0].name
            //     ? item.sortCodeId && item.creditAccount && item.debitAccount
            //     : item.sortCodeId;

            // if (!condition) {
            //     toast.info(t('UnbalancedJournalEntry'))
            // } // Skip if the item doesn't meet the condition

            // Check if the id is already in selectedRows
            const selectedIndex = newSelected.indexOf(id);

            // Add or remove the id based on its presence in selectedRows
            if (selectedIndex === -1) {
                // ID is not selected, so add it
                newSelected.push(id);
            } else {
                // ID is selected, so remove it
                newSelected.splice(selectedIndex, 1);
            }
        });

        setSelectedRows(newSelected);
    };


    const [addEditableItem, setAddEditableItem] = useState({
        checks: [],
        creditCards: [],
        cash: [],
        bankTransactions: [],
        date: new Date()
    });
    const [invoiceType, setInvoiceType] = useState('');
    const [invoiceTypeItem, setInvoiceTypeItem] = useState({});
    //signature 
    const [invoiceReference, setInvoiceReference] = useState('');
    const sigPad = useRef({});
    const [signatureReferenceInvoiceModalVisible, setSignatureReferenceInvoiceModalVisible] = useState(false);
    const signatureReferenceInvoiceModalVisibleRef = useRef();
    const [invoiceSignature, setInvoiceSignature] = useState('');
    const [ReferenceInvoiceModalVisible, setReferenceInvoiceModalVisible] = useState(false);
    const signatureReferenceFilled = (invoiceSignature && invoiceReference && !ReferenceInvoiceModalVisible) || (invoiceReference && ReferenceInvoiceModalVisible);

    const handleSignatureChange = () => {
        if (sigPad.current) {
            const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            setInvoiceSignature(signatureImage);
            setClient(prevClient => ({
                ...prevClient,
                signature: signatureImage
            }));
        }
    };

    const clearSignature = () => {
        if (sigPad.current) {
            sigPad.current.clear();
            setInvoiceSignature("");
        }
    };

    useEffect(() => {
        if (isUpdated) {
            handleReopenEdit(newItem);
            setIsUpdated(false); // Reset the flag
        }
    }, [isUpdated]);

    const handleSignatureReferenceSave = async () => {
        setSignatureReferenceInvoiceModalVisible(false)
        setAddNewSignatureReference(true);
        // setAddEditableItem(prevState => ({
        //     ...prevState,
        //     reference: invoiceReference,
        //     signature: invoiceSignature
        // }));
        // setModalVisible(true);
        await handleCreateReceiptV2();
    }

    const handleSignatureReferenceClose = async () => {
        setInvoiceSignature('');
        setInvoiceReference('');
        setSignatureReferenceInvoiceModalVisible(false);
    }


    const closeSignatureInvoiceModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (signatureReferenceInvoiceModalVisibleRef.current && !signatureReferenceInvoiceModalVisibleRef.current.contains(e.target)) {
            handleSignatureReferenceClose();
        }
    }

    const fetchDocumentsNotification = async (client, token) => {
        const documentCounts = await ClientDocumentCountService.getClientDocumentCountByCaseId(client.caseId, token);
        // Enrich clients with their total document counts
        const enrichedClient = {
            ...client,
            totalCounts: documentCounts.totalCounts || 0, // Default to 0 if no matching count is found
            documentCounts: documentCounts.documentCounts || {},
            documentIds: documentCounts.documentIds || {},
        };
        setInvoiceSignature(enrichedClient.signature);
        setClient(enrichedClient);
    }

    const handleOpenCreateModal = (type) => {
        setModalVisible(true);
        if (type === 'Payment') {
            setAddEditableItem(prevState => ({
                ...prevState,
                paymentAcceptanceType: 'תשלום'
            }));
        } else if (type === 'Approval') {
            setAddEditableItem(prevState => ({
                ...prevState,
                paymentAcceptanceType: 'תקבול'
            }));
        }

    }

    const handleOpenReceiptTaxModal = (type) => {

        setReceiptTaxModalVisible(true);
        setInvoiceType(type);

    }
    const handleConfirm = async () => {
        if (actionType === 'delete') {
            await handleConfirmDelete();
        } else if (actionType === 'closeMonth') {
            await CloseReportingMonth(true);
        } else if (actionType === 'openMonth') {
            await CloseReportingMonth(false);
        }
        else if (actionType === 'deleteAccountCard') {
            handleAccountCardDeleteOptions()
        }
    };

    const getConfirmMessage = () => {
        switch (actionType) {
            case 'closeMonth':
                return t('AreYouSureCloseMonth');
            case 'openMonth':
                return t('AreYouSureOpenMonth');
            default:
                return t('confirmDeleteMessage');

        }
    };

    const getTitleMessage = () => {
        switch (actionType) {

            case 'closeMonth':
                return t('ConfirmCloseMonth');
            case 'openMonth':
                return t('ConfirmOpenMonth');
            default:
                return t('confirmDeleteTitle');
        }
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        // Perform delete operation
        try {
            // Call the DeleteDocument function from DocumentService with the item's id
            const response = await DocumentService.DeleteDocument(type, itemIdToDelete, token);
            if (response.ok) {
                // Handle successful deletion, e.g., by updating your UI
                if (!isRange)
                    await fetchDocuments(sortDate);
                else
                    await fetchDataOnDateRangeChange(startDate, endDate);

                // Display a success message based on the selected language
                const successMessage = t('documentDeletedSuccessfully'); // Use t() to access translations
                toast.success(successMessage);
            } else {
                // Handle any errors or display an error message
                const errorResponse = await response.json();
                console.error('Error deleting document:', errorResponse);

                // Display an error message based on the selected language
                const errorMessage = t('errorDeletingDocument'); // Use t() to access translations
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Error deleting document:', error);
            // Display an error message based on the selected language
            const errorMessage = t('errorDeletingDocument'); // Use t() to access translations
            toast.error(errorMessage);
        }
        setIsModalVisible(false);
        setLoading(false);
    };

    const [isAccountCardDuplicate, setIsAccountCardDuplicate] = useState(false);

    const [paymentBeingEdited, setPaymentBeingEdited] = useState(false);
    const [itemEditing, setItemEditing] = useState(false);

    const handleEdit = async (item, showType = { type: 'all', index: -1 }) => {
        setShowType(showType);
        const index = filteredAndSortedData.findIndex((dataItem) => dataItem.id === item.id);

        let statusChecked = client.documentIds.checks.includes(item.id);
        setStatusCheck(!statusChecked);
        setItemEditing(true);
        item.docType = type;
        if (item.checks && item.checks.length > 0) {
            item.documentUrl = item.checks.map(check => check.documentUrl);
        } else {
            item.documentUrl = [];
        }
        setOpenPaymentAcceptanceCreateEditModal(true);
        setAddEditableItem({ ...item });
        setPaymentBeingEdited(true);
        const matchingAccountCard = accountCardOptions.find(card => card.cardId?.toString() === item.accountCodeId?.toString());
        if (matchingAccountCard) {
            setSelectedOption(matchingAccountCard);
        }

        setButtonDisabled(false);
        setCurrentIndex(index);
    };
    const handleReopenEdit = async (item) => {
        // await fetchEnums(user, token, client);
        handleEdit(item);
    }
    const handleChangeNew = (selectedOption, field) => {


        setSelectedOption(selectedOption);
        if (selectedOption.value1 === "AddItem") {
            setAccountCodeName("");
            setAccountCodeId("");
            setCompanyId("");
            addEditableItem.accountCaseId = "";
            addEditableItem.accountCodeId = "";
            addEditableItem.accountCodeName = "";
            setAddNewAccountCardDocument(true);
            setAddNewAccountCard(true);
        }
        else if (field == "accountCodeName") {

            setAccountCodeName(selectedOption.value);
            setCompanyId(selectedOption.accountCaseId);
            const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value);
            setAccountCodeId(accountCodeId.cardId);
            setInvoiceClientCaseId(accountCodeId.cardId);

            addEditableItem.accountCaseId = selectedOption.accountCaseId;
            addEditableItem.accountCodeId = accountCodeId.cardId;
            addEditableItem.accountCodeName = selectedOption.value;
        }

    };

    const handleMuiChange = (event, value) => {

        // const value = e.value;
        if (!value) {
            setAddEditableItem(prevState => ({
                ...prevState,
                accountCodeId: null,
            }));
            return;
        }
        if (value?.value1 === 'AddItem') {
            setAccountCodeName(value.searchTerm || "");
            setAccountCodeId(cardIdOption || '');
            setCompanyId("");
            // addEditableItem.accountCaseId = "";
            // addEditableItem.accountCodeId = "";
            // addEditableItem.accountCodeName = "";
            setAddNewAccountCardDocument(true);
            setAddNewAccountCard(true);
        }
        else {

            setAccountCodeName(value.name);
            setCompanyId(value.accountCaseId);
            setAccountCodeId(value.cardId);
            setInvoiceClientCaseId(value.cardId);

            // Update addEditableItem state
            setAddEditableItem(prevState => ({
                ...prevState,
                accountCaseId: value.accountCaseId,
                accountCodeId: value.cardId,
                accountCodeName: value.name,
            }));
            setSelectedOption({ ...value });
        }
    };

    const handleAccountEdit = (option) => {
        setShowAccountCardInputsEdit(true);
        setAccountCodeName(option.name);
        setAccountCodeId(option.cardId);
        setCompanyId(option.caseId);
        const account = accountCardOptions.find(account => account.cardId === option.cardId);
        setPalCode(account ? account.palCode : '');
        setOldSelectedAccountCardItem({
            cardId: option.cardId,
            caseId: option.caseId,
            accountCaseId: option.accountCaseId,
            name: option.name,
            palCode: '',
            client: null

        })
        setAddNewAccountCardDocument(true);
    };

    const handleAccountDelete = (option) => {
        setActionType('deleteAccountCard');
        setAccountCardIdToDelete(option.cardId);
        setIsModalVisible(true);
        // handleAccountCardDeleteClick(option.cardId);
    };

    const setReportingMonthDate = async (selectedDate, field) => {
        if (!selectedDate) {
            return;
        }

        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
        if (response1.ok) {
            const isClosedReportingMonth = await response1.json();
            if (isClosedReportingMonth) {
                toast.info(t('MonthReportAlreadyClosedTryAgain'));
                return
            }
            else {
                setAddEditableItem({ ...addEditableItem, [field]: newDate });
            }
        }
    }


    const handleDocumentUpload = (event) => {
        setDocuments(Array.from(event.target.files));
    };




    const closeCreateModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (modalCreateDocumentRef.current && !modalCreateDocumentRef.current.contains(e.target)) {
            handleClose();
        }
    }

    const closeQuestionModal = () => {
        setAddNewAccountCardDocument(false);
        setModalVisible(false);
        setOpenPaymentAcceptanceCreateEditModal(false);
        setChooseUploadDocument(true);
    }

    const handleClose = async (clearDocuments = true) => {

        setPriceMatchProblemString('');
        setIsOCRSaving(false);
        setIsAccountCardDuplicate(false);
        setDocumentReference("");
        setSelectedOption(null);
        setAccountCodeName("");
        setAccountCodeId("");
        setCheckTypeOption("");
        setDocumentReference("");
        setDescription("");
        // setAddNewAccountCard(false);
        setAddNewAccountCardDocument(false);
        setModalVisible(false);
        setOpenPaymentAcceptanceCreateEditModal(false);
        setChooseUploadDocument(true);
        setItemEditing(false);
        setPaymentBeingEdited(false);
        setAddEditableItem({
            checks: [],
            creditCards: [],
            cash: [],
            bankTransactions: [],
            date: new Date()
        });
        setInvoiceClientPhoneNumber('');
        setInvoiceClientEmail('');
        setInvoiceClientLocation('');
        setInvoiceDescription('');
        setInvoiceItems([]);
        if (clearDocuments) {
            setDocuments([]);
        }
        setReceiptTaxModalVisible(false);
    }

    const allFieldsFilled = documents && documents.length > 0;

    const handleAccountCardSave = async (AccountCodeId, AccountCodeName, AccountCaseId) => {

        const response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, AccountCaseId, token);
        if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };


    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            // Fetching accountCardOptions
            const accountCardResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            const accountCardData = await accountCardResponse.json();

            setEditAccountCardOptions(accountCardData);
            // Create a new object that maps cardId to name
            const accountCardMap = accountCardData.reduce((acc, item) => {
                acc[item.cardId] = item.name;
                return acc;
            }, {});
            setAccountCardMap(accountCardMap);
            const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountCardData];
            setAccountCardOptions(accountCardOptions);


            // Ferching checks data
            const checksTypeResponse = await EnumsService.GetChecksTypesByAccountantId(user.accountantId, token);
            const checkTypeOptions = await checksTypeResponse.json();
            setCheckTypeOptions(checkTypeOptions);

            // Show success toast notification
            setIsFirstUserFetched(true);
            // toast.success(t('fetchingEnumsSuccess'));

            const ClientItemsResponse = await EnumsService.GetClientItemsByCaseId(client.caseId, token);
            if (ClientItemsResponse.ok) {
                const ClientItemsData = await ClientItemsResponse.json();
                ClientItemsData.unshift({ makat: 'add-item', name: t('AddItem') });
                setClientItems(ClientItemsData);
            }


        } catch (error) {
            console.error("Error fetching enum data:", error);

            // Show error toast notification with translation
            toast.error(t('fetchingEnumsError'));

            // Handle the error appropriately
        }
        setLoading(false);
    };
    const fetchUserData = async () => {
        setLoading(true);
        try {
            const currentUser = await UserService.getCurrentUser();
            const currentToken = await UserService.getCurrentToken();
            if (currentUser && currentToken) {
                setUser(currentUser);
                //
                setToken(currentToken);

                // Get client data from location.state
                if (location.state && location.state.client && location.state.type) {
                    await fetchDocumentsNotification(location.state.client, currentToken);
                    setType(location.state.type)
                }
                await fetchEnums(currentUser, currentToken, location.state.client);
                // Fetch financial data for the current date on initial load only
            }
            else {
                navigate("/login");
            }


        } catch (error) {
            // Handle error - maybe navigate to login or show a message
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchUserData();

    }, []);

    const deleteAfterTransfer = async () => {
        await DocumentService.DeleteDocument(deleteDto.fromType, deleteDto.id, token);
        setShouldDelete(false);
        setDeleteDto({});
    }
    const handleTransferFile = async (file, dto) => {
        const success = await handleOCRSave([file], dto.toType, true);

        if (success) {
            // await DocumentService.DeleteDocument(dto.fromType, dto.id, token);
        } else {
            console.error('OCR save failed. Document not deleted.');
        }
    };

    useEffect(() => {
        const transferredKey = 'hasTransferred';
        const transferred = sessionStorage.getItem(transferredKey) || false;

        if (
            client &&
            !transferred && // Check if transfer has not been handled in this session
            location.state &&
            location.state.fileTransported &&
            location.state.dto
        ) {
            const { fileTransported, dto } = location.state;
            setDocuments([fileTransported]);
            setShouldDelete(true);
            setDeleteDto(location.state.dto);
            handleTransferFile(fileTransported, dto).then(() => {
                sessionStorage.setItem(transferredKey, 'true'); // Mark as transferred in sessionStorage
            });
        }
    }, [client, location.state]);

    useEffect(() => {
        // To handle multiple updates efficiently, consider iterating over documentUpdate keys
        Object.keys(documentUpdate).forEach(updateCaseId => {
            const update = documentUpdate[updateCaseId];
            // Update clients with new totalCounts for the matching caseId

            if (client?.caseId === updateCaseId) { // Check if the client's caseId matches the update's caseId
                const updatedClients = {
                    ...client,
                    totalCounts: update.totalCounts, // Update totalCounts from documentUpdate
                    documentIds: update.documentsIds,
                    documentsCount: update.documentsCount
                };
                setClient(updatedClients); // Update the state with the modified lzist
            }

        });
    }, [documentUpdate]);

    const fetchDocuments = async (selectedDate) => {
        setLoading(true);
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));

        try {
            const response = await DocumentService.GetDocumentbyDate(type, client.caseId, newDate, token)
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);

            if (response.ok && response1.ok) {
                const data = await response.json(); // Parse the JSON in the response
                const isClosedReportingMonth = await response1.json();
                setIsClosedReportingMonth(isClosedReportingMonth);
                extractVatByType(data);

                // Assuming `data` is your array of items
                const updatedData = data.map(item => {
                    // Add the new field `debitCreditAccountAmount` to each item
                    return {
                        ...item,
                        debitCreditAccountAmount: (item.amount ?? 0) + (item.withholdingTax ?? 0)
                    };
                });

                // Update the state with the modified data
                setData(updatedData);
                // toast.success(t('fetchDocumentsSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('fetchDocumentsFailure', { errorText }));
            }
        } catch (error) {

            toast.error(t('errorFetchingDocuments'));
        }
        setLoading(false);
    };


    useEffect(() => {

        if (isFirstUserFetched) {
            if (!isRange)
                fetchDocuments(sortDate);
            else
                fetchDataOnDateRangeChange(startDate, endDate)
        }
    }, [isFirstUserFetched]);

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`document-custom-input ${isRange ? "document-custom-input-range" : ""}`} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));
    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="document-custom-input1" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const CustomDownloadInput = React.forwardRef(({ value, placeholderText, onClick }, ref) => {
        return (
            <button className="download-document-custom-input" onClick={onClick} ref={ref}>
                {value || placeholderText}
            </button>
        );
    });



    const handleSortChange = (key, type) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };



    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    const handleAccountCardChange = (selectedOption, field) => {
        if (field === 'accountCodeId') {
            const accountCodeName = accountCardOptions.find(option => option.cardId === selectedOption.value)?.name;
            if (selectedOption.value < 0) {
                setEditedAccountCard(true)
                setOriginalAccountCardId(editedItem.accountCodeId)
            }
            setEditedItem({ ...editedItem, accountCodeId: selectedOption.value, accountCodeName });
        } else if (field === 'accountCodeName') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedItem({ ...editedItem, accountCodeId: '', accountCodeName: '', accountCaseId: '' });
                setEditedAddAccountCard(true);
                return;
            }
            const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value)?.cardId;
            setEditedItem({ ...editedItem, accountCodeId, accountCodeName: selectedOption.value, accountCaseId: selectedOption.accountCaseId });
        }
    };

    const handleAccountCardIdChange = (e) => {
        const accountCardIdValue = e.target.value;
        setEditedAccountCard(true);
        setOriginalAccountCardId(editedItem.accountCodeId); // Store the original accountCodeId

        // Store the new accountCodeId (whether positive or negative)
        setEditedItem({ ...editedItem, accountCodeId: accountCardIdValue });
    };

    const handleBackAccountCardIdChange = () => {

        setEditedAccountCard(false);
        if (originalAccountCardId) {
            const accountCodeName = accountCardOptions.find(option => option.accountCodeId === originalAccountCardId)?.accountCodeName;
            setEditedItem({ ...editedItem, accountCodeId: originalAccountCardId, accountCodeName: accountCodeName });

        }
        setOriginalAccountCardId(null); // Store the original accountCodeId
    };

    const handleSetSortDate = (date) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), 10);
        localStorage.setItem('Document' + location.state.type + '_Date', newDate);
        setSortDate(newDate);
        fetchDocuments(newDate);
    }
    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Check_StartDate', newDate)
        setStartDate(newDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(newDate), new Date(endDate));
        }
    };

    const handleEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Check_EndDate', endDate)
        setEndDate(endDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate));
        }
    };

    const handleDownloadStartDateChange = (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadStartDate(newDate);
    };

    const handleDownloadEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadEndDate(endDate);
    };

    const extractVatByType = (data) => {
        setDenseTableHeader([t("Total"), t("acceptedWithholdingTax"), t("paymentWithholdingTax")]);
        setDenseTableCreditDebitHeader([t("Difference"), t("TotalCreditAccount"), t("TotalDebitAccount")]);

        // Sum the WithholdingTax based on PaymentAcceptanceType
        let acceptedWithholdingSum = 0;
        let paymentWithholdingSum = 0;
        let creditSum = 0;
        let debitSum = 0;
        let total = 0;

        data.forEach(doc => {

            if (doc.paymentAcceptanceType === 'תקבול') {
                acceptedWithholdingSum += doc.withholdingTax || 0;
                debitSum += doc.withholdingTax;
            } else if (doc.paymentAcceptanceType === 'תשלום') {
                paymentWithholdingSum += doc.withholdingTax || 0;
                creditSum += doc.withholdingTax;
            }
            total += doc.amount
            // Logic based on paymentAcceptanceType
            if (doc.paymentAcceptanceType === 'תקבול') {
                // For "תקבול" (Receipt), checks the debit account and sum debit amounts from arrays (checks, creditCards, cash, bankTransactions)
                doc.checks?.forEach(check => {
                    debitSum += check.amount ?? 0;
                });

                doc.creditCards?.forEach(card => {
                    debitSum += card.amount ?? 0;
                });

                doc.cash?.forEach(cash => {
                    debitSum += cash.amount ?? 0;
                });

                doc.bankTransactions?.forEach(transaction => {
                    debitSum += transaction.amount ?? 0;
                });
                creditSum += doc.amount
            } else if (doc.paymentAcceptanceType === 'תשלום') {
                // For "תשלום" (Payment), checks the credit account and sum credit amounts from arrays (checks, creditCards, cash, bankTransactions)
                doc.checks?.forEach(check => {
                    creditSum += check.amount ?? 0;
                });

                doc.creditCards?.forEach(card => {
                    creditSum += card.amount ?? 0;
                });

                doc.cash?.forEach(cash => {
                    creditSum += cash.amount ?? 0;
                });

                doc.bankTransactions?.forEach(transaction => {
                    creditSum += transaction.amount ?? 0;
                });
                debitSum += doc.amount

            }
        });
        let diff = debitSum - creditSum;
        setAcceptedWithholdingTax(acceptedWithholdingSum);
        setPaymentWithholdingTax(paymentWithholdingSum);
        setDenseTableRows([{ Total: beautifyNumber(total), acceptedWithholdingTax: beautifyNumber(acceptedWithholdingSum), paymentWithholdingTax: beautifyNumber(paymentWithholdingSum) }]);
        setDenseTableCreditDebitRows([{ Difference: beautifyNumber(diff), TotalCreditAccount: beautifyNumber(creditSum), TotalDebitAccount: beautifyNumber(debitSum) }]);

    };
    async function fetchDataOnDateRangeChange(startDate, endDate) {

        setLoading(true)
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        try {
            const response = await DocumentService.GetDocumentbyDateRange(type, client.caseId, formattedStartDate, formattedEndDate, token)
            if (response.ok) {
                const data = await response.json(); // Parse the JSON in the response

                extractVatByType(data);
                // Assuming `data` is your array of items
                const updatedData = data.map(item => {
                    // Add the new field `debitCreditAccountAmount` to each item
                    return {
                        ...item,
                        debitCreditAccountAmount: (item.amount ?? 0) + (item.withholdingTax ?? 0)
                    };
                });

                // Update the state with the modified data
                setData(updatedData);
                // toast.success(t('documentFetchSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentFetchError', { errorText: error.message }));
        }

        setLoading(false)
    }

    const handleDownloadDocuments = async () => {
        setLoading(true);
        try {
            const dto = {
                CaseId: client.caseId,
                Date: downloadStartDate,
                EndDate: downloadEndDate,
                AccountCodeIds: downloadAccountCodeId,
                Amount: downloadAmount,
                CheckType: downloadCheckType
            };

            const blob = await DocumentService.DownloadCustomDocuments(type, token, dto); // Assuming you have token state or prop for authorization
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip'); // or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            toast.success(t('documentDownloadSuccess'));
        } catch (error) {
            console.error('Error downloading documents:', error);
            toast.error(t('documentDownloadError', { errorText: error.message }));
        }
        setDownloadStartDate(null);
        setDownloadEndDate(null);
        setDownloadAmount();
        setDownloadAccountCodeId([]);
        setDownloadCheckType('');
        setShowDownloadModal(false)
        setLoading(false);
    };

    const closeDownloadModal = () => {
        setShowDownloadModal(false);
    };

    const handleRangeChange = async () => {
        !isRange ? await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate)) : await fetchDocuments(new Date(sortDate));
        setIsRange(!isRange);
        localStorage.setItem('Check_IsRange', !isRange);
    }

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));


    const handleCloseAddNewAccountCardDocument = () => {
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };



    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '', accountCaseId: '' })
        setEditedAddAccountCard(false);
    };

    const handleRightClickAction = async (id, status, row) => {
        
        if (status === 'done') {
            await handleDoneClick(id);
        } else if (status === 'notDone') {
            await handleNotDoneClick(id);
        }
        await onRefresh();
    };
    // table functions
    const headerStyle = {
        backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
        color: "var(--Foundation-Blue-Normal, #304FFF)",
        textAlign: "center",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 'bold',
        lineHeight: "normal",
    }

    const [openedIframeUrl, setOpenedIframeUrl] = useState('');
    const [bigIframeModalOpen, setBigIframeModalOpen] = useState(false);
    const openPdfInNewTab = (pdfUrl) => {
        // window.open(pdfUrl, '_blank');

        setOpenedIframeUrl(pdfUrl ? pdfUrl : NoDocumentsPdf);
        setBigIframeModalOpen(true);
    };
    const handleCloseIframeModal = () => {
        setBigIframeModalOpen(false);
        setOpenedIframeUrl('');
    };

    const [openRows, setOpenRows] = useState(() => filteredAndSortedData.map(() => false));


    useEffect(() => {
        setOpenRows(filteredAndSortedData.map(() => false));
    }, [filteredAndSortedData]);


    const handleToggleRow = (index) => {
        setOpenRows((prevOpenRows) => {
            const newOpenRows = [...prevOpenRows];
            newOpenRows[index] = !newOpenRows[index];
            return newOpenRows;
        });
    };



    const [chooseUploadDocument, setChooseUploadDocument] = useState(true);
    const [openPaymentAcceptanceCreateEditModal, setOpenPaymentAcceptanceCreateEditModal] = useState(false);
    const [originalTaxClearance, setOriginalTaxClearance] = useState(null);
    const [paymentAcceptanceItems, setPaymentAcceptanceItems] = useState([]);
    const [addItemFlags, setAddItemFlags] = useState([]);
    const [clientItems, setClientItems] = useState([]);
    const [newItems, setNewItems] = useState([]); // To store new items
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isOCRSaving, setIsOCRSaving] = useState(false);



    //this is for button to disable and enable the ability to click it
    const [accountType, setAccountType] = useState(null);
    useEffect(() => {
        let unionArray = [];
        if (addEditableItem) {
            unionArray = [
                ...(addEditableItem.checks || []),
                ...(addEditableItem.creditCards || []),
                ...(addEditableItem.cash || []),
                ...(addEditableItem.bankTransactions || [])
            ];
        }

        if (unionArray.length > 0) {
            const allAmountsFilled = unionArray.every(item => item.amount !== undefined && item.amount !== null && item.amount !== '');
            const allFieldsFilled = allAmountsFilled &&
                (addEditableItem.accountCaseId !== undefined && addEditableItem.accountCaseId !== null && addEditableItem.accountCaseId !== '' ||
                    addEditableItem.accountCodeId !== undefined && addEditableItem.accountCodeId !== null && addEditableItem.accountCodeId !== '' ||
                    addEditableItem.accountCodeName !== undefined && addEditableItem.accountCodeName !== null && addEditableItem.accountCodeName !== '') &&
                addEditableItem.paymentAcceptanceType !== undefined && addEditableItem.paymentAcceptanceType !== null && addEditableItem.accountType !== '';



            setButtonDisabled(!allFieldsFilled);
        } else {
            setButtonDisabled(true);
        }
    }, [addEditableItem, accountCodeId, accountType]);

    //const [description, setDescription] = useState('');


    const handleChooseUploadDocument = async (choice) => {
        setChooseUploadDocument(choice);
        if (choice === false)
            handleOpenCreateNewInvoice();
    }

    const handleNavigateToScreen = async (item) => {
        navigate('/ReceiptTax', {
            state: { item: item, type: invoiceType, client: client }
        });
    }
    const handleOpenCreateNewInvoice = () => {
        setOpenPaymentAcceptanceCreateEditModal(true);
        setModalVisible(false);
    }

    const handleChangeOriginalTaxClearance = (event) => {
        const value = event.target.value;
        addEditableItem.withholdingTax = value;
        setOriginalTaxClearance(value);

    };

    const handleAddEditableItemChange = (name, value) => {
        setAddEditableItem(prev => ({ ...prev, [name]: value }));

    };
    const handleRemoveItem = (type, index) => {
        let count = 0;

        // Update paymentAcceptanceItems
        const newItems = paymentAcceptanceItems.filter(item => {
            if (item.type === type) {
                if (count === index) {
                    count++;
                    return false;
                }
                count++;
            }
            return true;
        });
        setPaymentAcceptanceItems(newItems);

        // Update addEditableItem
        setAddEditableItem(prevItem => {
            const updatedTypeItems = prevItem[type].filter((_, idx) => idx !== index);
            return { ...prevItem, [type]: updatedTypeItems };
        });
    };




    const handleChangeAccountType = (selectedOption) => {
        setAccountType(selectedOption.value);
        setAddEditableItem((prevState) => ({
            ...prevState,
            paymentAcceptanceType: selectedOption ? selectedOption.value : ''
        }));
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        addEditableItem.description = event.target.value;
    };
    // Add a new item
    const handleAddItem = (theType) => {
        const newItem = {
            type: theType,
            description: '',
            amount: '',
            paymentDate: new Date(),
            bankName: '',
            bankCode: '',
            bankBranchCode: '',
            supplierAccountNumber: '',
            bankTransactionsCode: Constants.PaymentAcceptanceConstantsAccountcards.GeneralBank,
            creditAccount: "",
            debitAccount: "",
        };

        // Update paymentAcceptanceItems
        setPaymentAcceptanceItems(prevItems => [...prevItems, newItem]);

        // Update addEditableItem
        setAddEditableItem(prevItem => ({
            ...prevItem,
            [theType]: [...prevItem[theType], newItem]
        }));

    };

    const handleCloseAddItem = (index) => {
        const updatedFlags = [...addItemFlags];
        updatedFlags[index] = false;
        setAddItemFlags(updatedFlags);
    };

    // Update an existing item
    const handleItemChange = (type, index, field, value) => {
        setPaymentAcceptanceItems(prevItems => {
            let typeIndex = -1;
            const updatedItems = prevItems.map((item, idx) => {
                if (item.type === type) {
                    typeIndex += 1;
                    if (typeIndex === index) {
                        return { ...item, [field]: value };
                    }
                }
                return item;
            });

            // Update the corresponding field in addEditableItem

            setAddEditableItem(prevItem => {
                const updatedTypeItems = prevItem[type].map((item, idx) => {
                    if (idx === index) {
                        return { ...item, [field]: value };
                    }
                    return item;
                });

                return { ...prevItem, [type]: updatedTypeItems };
            });
            // 
            return updatedItems;
        });
    };

    const [receiptQuestionModal, setReceiptQuestionModal] = useState(false);


    //creating
    const handleClickDoneCreation = () => {
        handleSaveNewReceipt();
    }

    const deleteValuesOnExit = () => {
        setAddEditableItem({
            checks: [],
            creditCards: [],
            cash: [],
            bankTransactions: [],
            date: new Date()
        });
        setSelectedOption(null)
        setAccountType(null);
        setAccountCodeName('');
        setAccountCodeId('');
        setCompanyId('');
        setOriginalTaxClearance('');
        setPaymentAcceptanceItems([]);
    }



    const handleSaveNewReceipt = (noInvoice = true) => {

        setReceiptQuestionModal(false);
        addEditableItem.withholdingTax = originalTaxClearance ? originalTaxClearance : 0;

        if (noInvoice) { handleAdd(); }
        return
    }


    const handleAdd = async () => {
        setModalVisible(false);
        setOpenPaymentAcceptanceCreateEditModal(false);
        setLoading(true);

        // Check for duplicate account card
        const matchingAccountCard = accountCardOptions.find(card => card.accountCaseId === addEditableItem.accountCaseId);
        let match = false;
        if (matchingAccountCard) {
            setIsAccountCardDuplicate(true);
            match = true;
        }

        let response2;


        const formData = new FormData();
        formData.append("CaseId", client.caseId);
        formData.append("AccountantId", user.accountantId);
        formData.append("DocumentFileType", 'Checks');
        formData.append("PaymentAcceptanceType", addEditableItem.paymentAcceptanceType);
        formData.append("AccountCodeName", addEditableItem.accountCodeName);
        formData.append("AccountCodeId", addEditableItem.accountCodeId);
        formData.append("AccountCaseId", addEditableItem.accountCaseId);
        formData.append("WithholdingTax", addEditableItem.withholdingTax?.toString());
        formData.append("Date", new Date(addEditableItem.date).toISOString());
        formData.append("reportingMonthDate", new Date(sortDate).toISOString());

        // Append checks, credit cards, cash, and bank transactions to formData
        if (addEditableItem.checks) {
            const checks = addEditableItem.checks.map(check => ({
                bankName: check.bankName,
                paymentDate: new Date(check.paymentDate ? check.paymentDate : Date.now()).toISOString(),
                description: check.description,
                amount: check.amount,
                bankCode: check.bankCode,
                bankBranchCode: check.bankBranchCode,
                supplierAccountNumber: check.supplierAccountNumber,
                documentUrl: check.documentUrl || "",
                creditAccount: check.creditAccount,
                debitAccount: check.debitAccount
            }));
            formData.append('Checks', JSON.stringify(checks));
        }

        if (addEditableItem.creditCards) {
            const creditCards = addEditableItem.creditCards.map(card => ({
                paymentDate: new Date(card.paymentDate ? card.paymentDate : Date.now()).toISOString(),
                description: card.description,
                amount: card.amount,
                creditAccount: card.creditAccount,
                debitAccount: card.debitAccount
            }));
            formData.append('CreditCards', JSON.stringify(creditCards));
        }

        if (addEditableItem.cash) {
            const cash = addEditableItem.cash.map(cashItem => ({
                paymentDate: new Date(cashItem.paymentDate ? cashItem.paymentDate : Date.now()).toISOString(),
                description: cashItem.description,
                amount: cashItem.amount,
                creditAccount: cashItem.creditAccount,
                debitAccount: cashItem.debitAccount
            }));
            formData.append('Cash', JSON.stringify(cash));
        }

        if (addEditableItem.bankTransactions) {
            const bankTransactions = addEditableItem.bankTransactions.map(transaction => ({
                paymentDate: new Date(transaction.paymentDate ? transaction.paymentDate : Date.now()).toISOString(),
                description: transaction.description,
                amount: transaction.amount,
                creditAccount: transaction.creditAccount,
                debitAccount: transaction.debitAccount
            }));
            formData.append('BankTransactions', JSON.stringify(bankTransactions));
        }
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });

        try {

            const response = await DocumentService.UploadDocument(type, formData, token);

            if (response.ok) {
                toast.success(t('InvoiceReceiptCreated'));
                if (shouldDelete) {
                    await deleteAfterTransfer()
                }
            } else {
                const errorText = await response.text();
                console.error(t('FailedToUploadDocument'), errorText);
                toast.error(t('InvoiceReceiptDidNotCreated', { errorText: errorText }));
            }
        } catch (error) {
            console.error(t('InvoiceReceiptDidNotCreated'), error);
        } finally {
            setLoading(false);
        }
        await onRefresh();
        handleClose();
    };


    const handleSave = async () => {
        try {
            setModalVisible(false);
            setOpenPaymentAcceptanceCreateEditModal(false);
            setLoading(true);
            // Prepare the data to be sent in the request body
            const updatedDocument = {
                id: addEditableItem.id,
                caseId: addEditableItem.caseId,
                accountCodeName: addEditableItem.accountCodeName,
                accountCodeId: addEditableItem.accountCodeId,
                reference: addEditableItem.reference,
                amount: addEditableItem.amount,
                uploadDate: addEditableItem.uploadDate,
                paymentAcceptanceType: addEditableItem.paymentAcceptanceType,
                description: addEditableItem.description,
                withholdingTax: addEditableItem.withholdingTax,
                accountCaseId: addEditableItem.accountCaseId,
                checks: addEditableItem.checks,
                creditCards: addEditableItem.creditCards,
                cash: addEditableItem.cash,
                bankTransactions: addEditableItem.bankTransactions,
                date: addEditableItem.date ? new Date(addEditableItem.date).toISOString() : new Date().toISOString(),
                reportingMonthDate: new Date(addEditableItem.reportingMonthDate).toISOString(),
                confirmationNumber: addEditableItem.confirmationNumber,
                // creditAccount: addEditableItem.creditAccount,
                // creditAccountAmount: addEditableItem.creditAccountAmount,
                // debitAccount: addEditableItem.debitAccount,
                // debitAccountAmount: addEditableItem.debitAccountAmount,
            };

            // Call the UpdateDocument function from DocumentService
            const response = await DocumentService.UpdateDocument(type, token, updatedDocument);
            // if (addNewAccountCardDocument) {
            //     setAddNewAccountCardDocument(false);
            //     const response1 = await EnumsService.AddAccountCard(
            //         user.caseId,
            //         addEditableItem.accountCodeName,
            //         token,
            //         addEditableItem.accountCaseId
            //     );
            // }
            if (response.ok) {
                // Handle successful update
                if (addEditableItem.status === 'done') {
                    await handleDoneClick(addEditableItem.id);
                } else if (addEditableItem.status === 'notDone') {
                    await handleNotDoneClick(addEditableItem.id);
                }
                toast.success(t('documentUpdateSuccess'));
            } else {
                // Handle any errors or display an error message
                toast.error(t('documentUpdateError'));
            }
        } catch (error) {
            console.error('documentUpdateError:', error);
            // Show an error toast message
            toast.error(t('documentUpdateError'));
        }
        await onRefresh();
        handleClose(); // Function to revert or exit the edit mode
        setLoading(false);

    };

    const pageData = useMemo(() => {
        const start = currentPage * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredAndSortedData.slice(start, end);
    }, [filteredAndSortedData, currentPage, rowsPerPage]);

    // const memoizedRows = useMemo(() => pageData.map((row) => (
    //     <Row
    //         key={row.id}
    //         row={row}
    //         open={!!openRows[row.id]}
    //         handleToggleRow={() => handleToggleRow(row.id)}
    //         isItemSelected={isSelected(row.id)}
    //     />
    // )), [pageData, openRows, selectedRows]);

    const [selectedOption, setSelectedOption] = useState("");



    // invoice type
    const [chooseInvoiceModal, setChooseInvoiceModal] = useState(false);
    const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
    const [invoiceTypeInModal, setInvoiceTypeInModal] = useState(false);
    const closeCreateInvoiceModal = (e) => {
        setChooseInvoiceModal(false);
        setInvoiceModalVisible(false);
        deleteValuesOnExit();
        handleClose();

    }

    const handleInvoiceItemChange = (index, field, value) => {
        if (addItemFlags[index]) {
            setNewItems(prevNewItems => {
                const newItemsCopy = [...prevNewItems];
                newItemsCopy[index] = {
                    ...newItemsCopy[index],
                    [field]: value,
                    caseId: client.caseId // Assuming caseId is available here
                };
                return newItemsCopy;
            });
        }
        if (field === "discount" && (parseInt(value) > 100 || parseInt(value) < 0)) {
            return;
        }
        setInvoiceItems(prevItems => {
            const updatedItems = prevItems.map((item, idx) => idx === index ? { ...item, [field]: value } : item);
            return updatedItems;
        });
    };

    const openChooseModal = () => {
        // handleSaveNewReceipt(false); //to save the data
        setChooseInvoiceModal(true);
        setReceiptQuestionModal(false);
    }

    const handleOpenSpecificInvoiceCreator = (type) => {
        // setCurrentInvoiceCreatingType(type);
        setChooseInvoiceModal(false);
        setIsInvoiceReceipt(type?.toString() === 'ReceiptTax');
        setInvoiceTypeInModal(type);

        setInvoiceTypeInModal(type);
        setChooseInvoiceModal(false);
        setInvoiceModalVisible(true);


    };
    //invoice creating
    const modalInvoiceCreateDocumentRef = useRef();
    const [invoiceDescription, setInvoiceDescription] = useState('');

    const [invoiceClientName, setInvoiceClientName] = useState('');
    const [invoiceClientCaseId, setInvoiceClientCaseId] = useState('');
    const [invoiceClientLocation, setInvoiceClientLocation] = useState('');
    const [invoiceClientEmail, setInvoiceClientEmail] = useState('');
    const [invoiceClientPhoneNumber, setInvoiceClientPhoneNumber] = useState('');

    const [invoiceAccountCodeId, setInvoiceAccountCodeId] = useState('');
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [addNewItem, setAddNewItem] = useState(false);
    const [addNewAccountCard, setAddNewAccountCard] = useState(false);
    const [addNewSignatureReference, setAddNewSignatureReference] = useState(false);
    const [priceIncludingVAT, setPriceIncludingVAT] = useState(false);




    const handlePriceIncludingVATChange = (event) => {
        setPriceIncludingVAT(event.target.value === 'true');
    };

    const handleInvoiceItem = (index, selectedOption) => {
        if (selectedOption.value === 'add-item') {
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = true;
            setAddItemFlags(updatedFlags);
        } else {
            const accountCard = clientItems.find(option => option.makat === selectedOption.value);
            handleInvoiceItemChange(index, 'makat', accountCard.makat);
            handleInvoiceItemChange(index, 'name', accountCard.name);
            // Reset flag in case it was set
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = false;
            setAddItemFlags(updatedFlags);
        }
    };


    const allClientItemFieldsFilled = invoiceItems
        && (invoiceItems.length > 0 || invoiceTypeInModal.toString() !== 'ReceiptTax')
        && (invoiceTypeInModal.toString() !== 'ReceiptTax' || invoiceItems.every(item => {
            const hasRequiredFields = item.name && item.makat && item.price && item.discount >= 0; // Check if name, makat, and price are provided
            item.quantity = item.quantity || 1; // Default quantity to 1 if not provided
            item.discount = item.discount || 0; // Default discount to 0 if not provided
            return hasRequiredFields;
        }));



    const [allFieldsCheckedInReceipt, setAllFieldsCheckedInReceipt] = useState(false);
    useEffect(() => {
        if (invoiceClientCaseId !== '' && invoiceClientPhoneNumber !== '') {
            setAllFieldsCheckedInReceipt(true);
        } else {
            setAllFieldsCheckedInReceipt(false)
        }

    }, [invoiceClientCaseId, invoiceClientPhoneNumber]);




    const handleInvoiceClose = async () => {
        handleClose();
        setInvoiceDescription('');
        setInvoiceClientName('');
        setInvoiceClientCaseId('');
        setInvoiceClientLocation('');
        setInvoiceClientEmail('');
        setInvoiceAccountCodeId('');
        setInvoiceItems([]);
        setInvoiceDate(new Date());
        setInvoiceSignature('');
        setInvoiceReference('');
        setInvoiceClientPhoneNumber('');
        setAddNewAccountCard('');
        setAddNewAccountCardDocument(false);
        setPriceIncludingVAT(false);
        setInvoiceModalVisible(false);
        setChooseInvoiceModal(false);
        deleteValuesOnExit();
    }


    const handleAddInvoiceItem = () => {
        const newItem = { makat: '', name: '', quantity: 1, price: null, discount: 0 };
        setInvoiceItems([...invoiceItems, newItem]);
    };

    const handleRemoveInvoiceItem = (index) => {
        const newItems = invoiceItems.filter((_, idx) => idx !== index);
        setInvoiceItems(newItems);
    };
    // create with invoice
    const [isInvoiceReceipt, setIsInvoiceReceipt] = useState(false);



    const onRefresh = async () => {
        setLoading(true);
        await fetchUserData();
        if (!isRange)
            fetchDocuments(sortDate);
        else
            fetchDataOnDateRangeChange(startDate, endDate)
        await fetchEnums(user, token, client);
    };

    const handleTransferRefresh = async () => {
        setOpenPaymentAcceptanceCreateEditModal(false);
        await onRefresh();
    }

    const saveNewItemsToDatabase = async () => {
        // Filter out undefined items
        const filteredItems = newItems.filter(item => item !== undefined);

        // Assuming EnumsService.AddClientItems expects an array of items
        const response = await EnumsService.AddClientItems(filteredItems, token);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Reset newItems after saving
        setNewItems([]);
        // Optionally reset addItemFlags if necessary
        setAddItemFlags([]);

        return response;
    };


    const updateLocationState = async (newClientData) => {

        const updatedClientData = {
            ...client,
            ...newClientData
        };


        // Navigate with the updated client data and replace the current state
        navigate(location.pathname, {
            replace: true,
            state: { client: updatedClientData, type: 'PaymentAcceptances' }
        });
        window.location.reload();
    };


    const handleCreateReceiptV2 = async () => {
        setLoading(true);
        setInvoiceModalVisible(false);
        setChooseInvoiceModal(false);
        //check account card


        const matchingAccountCard = accountCardOptions.find(card => card.accountCaseId === addEditableItem.accountCaseId);

        let match = false;
        if (matchingAccountCard) {
            setIsAccountCardDuplicate(true);
            match = true;

        }

        let response2;
        if (!isOCRSaving && !isAccountCardDuplicate) {
            let response2;

            if (addEditableItem.paymentAcceptanceType === 'תשלום') {

                if (addNewAccountCard) {
                    response2 = await EnumsService.AddAccountCard(client.caseId, addEditableItem.accountantId, addEditableItem.accountCodeName, addEditableItem.accountCaseId, "לקוחות", token);
                    setAddNewAccountCard(false);
                }
            } else {

                if (addNewAccountCard) {
                    response2 = await EnumsService.AddAccountCard(client.caseId, addEditableItem.accountantId, addEditableItem.accountCodeName, addEditableItem.accountCaseId, "ספקים", token);
                    setAddNewAccountCard(false);
                }
            }
            if (addNewAccountCard) {
                if (!response2.ok) {

                    throw new Error(`HTTP error! status: ${response2.status}`);
                }
            }
        }

        let Receipt = {
            WithholdingTax: addEditableItem.withholdingTax || 0,
            IsInvoiceReceipt: isInvoiceReceipt,
            Title: isInvoiceReceipt ? 'חשבונית מס קבלה' : 'קבלה'
        };

        const newDocument = {
            CaseId: client.caseId,
            AccountantId: client.accountantId,
            Name: client.name,
            Description: addEditableItem.description,
            Reference: invoiceReference || "",
            ClientName: addEditableItem.accountCodeName,
            ClientCaseId: invoiceClientCaseId ? invoiceClientCaseId : addEditableItem.accountCaseId || "",
            PhoneNumber: client.phoneNumber,
            Email: client.email?.toLowerCase(),
            Website: client.website,
            Location: client.location,
            ClientLocation: invoiceClientLocation,
            ClientEmail: invoiceClientEmail,
            Signature: invoiceSignature || "",
            AccountCodeId: addEditableItem.accountCodeId,
            PriceIncludingVAT: priceIncludingVAT,
            ClientPhoneNumber: invoiceClientPhoneNumber,
            date: new Date(invoiceDate).toISOString(),
            reportingMonthDate: new Date(sortDate).toISOString(),
            ...Receipt
        };


        const formData = new FormData();

        for (const key in newDocument) {

            if (newDocument[key]) {

                formData.append(key, newDocument[key]);


            }
        }

        const appendArrayField = (fieldName, dataArray) => {


            if (dataArray && dataArray.length > 0) {
                const formattedData = dataArray.map(item => ({
                    ...item,
                    paymentDate: item.paymentDate ? new Date(item.paymentDate).toISOString() : new Date().toISOString(),
                    amount: parseFloat(item.amount) || 0,
                }));
                formData.append(fieldName, JSON.stringify(formattedData));
            }
        };



        appendArrayField('Checks', addEditableItem.checks);
        appendArrayField('CreditCards', addEditableItem.creditCards);
        appendArrayField('Cash', addEditableItem.cash);
        appendArrayField('BankTransactions', addEditableItem.bankTransactions);

        if (invoiceItems && invoiceItems.length > 0) {
            const items = invoiceItems.map(item => ({
                makat: item.makat || "",
                name: item.name || "",
                quantity: item.quantity || 0,
                price: item.price || 0,
                discount: item.discount || 0
            }));
            formData.append('Items', JSON.stringify(items));
        }

        try {
            documents.forEach(document => {
                formData.append("documents", document, document.name);
            });
            await DocumentService.CreateInvoiceWithDocuments("PaymentAcceptances", "GeneratePaymentAcceptanceDocumentPdfV2", formData, client.caseId, token);


            if (newItems.length > 0) {
                await saveNewItemsToDatabase();
            }
            // if (addNewAccountCardDocument) {
            //     setAddNewAccountCardDocument(false);
            //     const response1 = await EnumsService.AddAccountCard(
            //         user.caseId,
            //         addEditableItem.accountCodeName,
            //         token,
            //         addEditableItem.accountCaseId
            //     );
            // }
            toast.success(t('InvoiceReceiptCreated'));
            if (shouldDelete) {
                await deleteAfterTransfer()
            }
            if (addNewSignatureReference) {
                const Client = await ClientService.GetClient(token, client.caseId);

                setClient(Client);
                updateLocationState(Client);
            }
            else {

                await onRefresh();
            }
        } catch (error) {
            console.error('Error creating invoice and opening file:', error);
            toast.error(t('InvoiceReceiptDidNotCreated'));

        } finally {
            handleInvoiceClose();
            setLoading(false);
        }
    };


    //ocr save
    const handleOCRSave = async (documents, returnResult = false) => {

        setLoading(true);
        handleClose(false);
        setIsOCRSaving(true);

        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('DocumentFileType', "Checks");
        formData.append('DocumentFileType', type);
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });
        try {


            const response = await DocumentService.UploadOCRDocument(type, formData, token);


            if (response.ok) {
                const responseData = await response.json();

                setAddEditableItem(responseData);
                setOpenPaymentAcceptanceCreateEditModal(true);

                setInvoiceClientCaseId(responseData.accountCaseId);

                //checking accountCard:
                const accountCardResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
                const accountCardData = await accountCardResponse.json();
                const matchingAccountCard = accountCardData.find(card => card.accountCaseId === responseData.accountCaseId);

                if (matchingAccountCard) {
                    setIsAccountCardDuplicate(true);
                    // Set the selected option to be the matching one
                    setSelectedOption(matchingAccountCard);
                } // also add here selected value for kabala



                setButtonDisabled(false);
                if (returnResult) {
                    return true; // Return success if requested
                }
            } else {

                const errorText = response.data?.message || t('UnknownError');
                console.error(errorText);
                // toast.success(t('Data has been fetched'));
                setLoading(false);
                if (returnResult) {
                    return false; // Return success if requested
                }
            }
        } catch (error) {
            console.error('Error during upload:', error);

            toast.error(t('documentUploadError'));
            setLoading(false);
            if (returnResult) {
                return false; // Return success if requested
            }
        } finally {
            //hideProcessingToast();
            setLoading(false);
        }
    };
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleOCRSaveV2 = async (documents) => {

        setLoading(true);
        handleClose(false);
        setIsOCRSaving(true);

        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('DocumentFileType', "Checks");
        formData.append('DocumentFileType', type);

        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });
        try {


            const response = await DocumentService.UploadOCRDocument(type, formData, token);


            if (response.ok) {
                const responseData = await response.json();
                const base64Files = await Promise.all(documents.map(file => convertFileToBase64(file)));

                // responseData.documents = documents.map(document => ({
                //     name: document.name,
                //     size: document.size,
                //     type: document.type,
                //     lastModified: document.lastModified
                // }));
                responseData.documents = documents;
                setFiles(documents || []);
                // responseData.documents = documents
                setInvoiceTypeItem(responseData);
                handleNavigateToScreen(responseData);


            } else {

                const errorText = response.data?.message || t('UnknownError');
                console.error(errorText);
                // toast.success(t('Data has been fetched'));
                setLoading(false);

            }
        } catch (error) {
            console.error('Error during upload:', error);

            toast.error(t('documentUploadError'));
            setLoading(false);

        } finally {
            //hideProcessingToast();
            setLoading(false);
        }
    };

    // const setItemsAfterOCR = (responseData) => {
    //     setCompanyId(responseData.accountCaseId);
    //     setAccountCodeName(responseData.accountCodeName);
    //     setAccountCodeId(responseData.accountCodeId);

    //     //accountCardOptions

    //     setDescription(responseData.description);
    //     setOriginalTaxClearance(responseData.withholdingTax);
    // }

    //new set functions
    const setAccount = (field, input) => {
        //switch case on field
        switch (field) {
            case 'companyId':
                setCompanyId(input);
                setInvoiceClientCaseId(input);

                break;
            case 'accountCodeName':
                setAccountCodeName(input);

                break;
            case 'accountCodeId':
                setAccountCodeId(input);

                break;
            default:

        }
    }

    const [priceMatchProblemString, setPriceMatchProblemString] = useState('');
    const handleReceiptTaxSave = () => {
        let totalAmount = 0;

        addEditableItem.checks.forEach(check => {
            totalAmount += parseFloat(check.amount);
        });

        addEditableItem.creditCards.forEach(creditCard => {
            totalAmount += parseFloat(creditCard.amount);
        });

        addEditableItem.cash.forEach(cash => {
            totalAmount += parseFloat(cash.amount);
        });

        addEditableItem.bankTransactions.forEach(bankTransaction => {
            totalAmount += parseFloat(bankTransaction.amount);
        });

        let totalItemsAmount = 0;
        invoiceItems.forEach(item => {
            let price = parseFloat(item.price);
            price *= item.quantity;
            const discount = parseFloat(item.discount);
            const amountAfterDiscount = (price - (discount / 100) * price);
            totalItemsAmount += amountAfterDiscount;
        });
        //calculate invoice total


        if (priceIncludingVAT) {
            if (totalAmount === totalItemsAmount + addEditableItem.withholdingTax) {
                handleCheckSign();
                setPriceMatchProblemString('');
            } else {

                let errorText = t('ItemPrice') + ' (' + totalAmount + '), ' + t('TotalProductPriceMismatch') + ' (' + totalItemsAmount + ')';
                toast.error(t('TotalProductPriceMismatch', { errorText }));
                setPriceMatchProblemString(t('ItemPrice') + ' (' + totalAmount.toString() + '), ' + t('TotalProductPriceMismatch') + ' (' + totalItemsAmount.toString() + ')');
            }

        } else {
            const priceWithVAT = totalItemsAmount * (1 + VAT_RATE);
            if (totalAmount === priceWithVAT + addEditableItem.withholdingTax) {
                handleCheckSign();
                setPriceMatchProblemString('');
            } else {

                let errorText = t('ItemPrice') + ' (' + totalAmount + '), ' + t('TotalProductPriceMismatch') + ' (' + priceWithVAT + ')';
                toast.error(t('TotalProductPriceMismatch', { errorText }));
                setPriceMatchProblemString(t('ItemPrice') + ' (' + totalAmount.toString() + '), ' + t('TotalProductPriceMismatch') + ' (' + priceWithVAT.toString() + ')');
            }
        }

    }
    const validateIsraeliPhoneNumber = (phoneNumber) => {
        const regex = /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;
        return regex.test(phoneNumber);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleCheckSign = async () => {

        let wrongInputs = false;
        if (!validateIsraeliPhoneNumber(invoiceClientPhoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            wrongInputs = true;
        }

        if (!validateEmail(invoiceClientEmail) && invoiceClientEmail !== '') {
            toast.error(t('InvalidEmailAddress'));
            wrongInputs = true;
        }

        if (wrongInputs) {
            return;
        }



        setInvoiceModalVisible(false);

        if (isInvoiceReceipt) {
            if (client.signature && client.invoicesNumbers) {

                await handleCreateReceiptV2()
            }
            else if (client.signature && !client.invoicesNumbers) {

                setSignatureReferenceInvoiceModalVisible(true);
                setAddNewSignatureReference(true);
                setReferenceInvoiceModalVisible(true);
            }
            else {

                setSignatureReferenceInvoiceModalVisible(true);
                setAddNewSignatureReference(true);
            }
        }
        else {




            if (client.signature && client.receiptNumbers) {

                await handleCreateReceiptV2()
            } else if (client.signature && !client.receiptNumbers) {

                setSignatureReferenceInvoiceModalVisible(true);
                setReferenceInvoiceModalVisible(true);
                setAddNewSignatureReference(true);
            } else {

                setSignatureReferenceInvoiceModalVisible(true);
                setAddNewSignatureReference(true);
                setReferenceInvoiceModalVisible(false);
            }
        }

    }

    const handleSetInvoiceDate = async (date) => {
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, date, token);
        const isClosedReportingMonth = await response1.json();
        if (isClosedReportingMonth) {
            toast.info(t('MonthReportAlreadyClosedTryAgain'));
            return
        }
        setInvoiceDate(date)
    }



    const setPaymentDate = async (date) => {
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, date, token);
        const isClosedReportingMonth = await response1.json();
        if (isClosedReportingMonth) {
            toast.error(t('MonthReportAlreadyClosedTryAgain'));
            return
        }
        setAddEditableItem((prevItem) => ({
            ...prevItem,
            date: date.toISOString()
        }));
    }

    const CloseReportingMonth = async (status) => {
        setLoading(true);
        try {
            const response = await ClientService.UpdateReportingMonth(client.caseId, sortDate, status, token);
            if (response.ok) {
                toast.success(status ? t('MonthReportClosedSuccess') : t('MonthReportOpenedSuccess'),
                );
                setIsClosedReportingMonth(status)
            } else {
                const errorText = await response.text();
                console.error(t('MonthReportNotClosed'), errorText);
                toast.error(status ? `${t('MonthReportNotClosed')}: ${errorText}` : `${t('MonthReportNotOpened')}: ${errorText}`,
                );
            }
        } catch (error) {
            console.error(t('MonthReportNotClosed'), error);
            toast.error(status ? `${t('MonthReportNotClosed')}: ${error}` : `${t('MonthReportNotOpened')}: ${error}`,
            );
        } finally {
            setLoading(false);
            setIsModalVisible(false)
        }
    };
    const isClosedReportingMonthAndIsRange = !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth;
    const handleAccountCardDeleteOptions = async () => {
        try {
            const item = addEditableItem;
            setIsModalVisible(false);
            handleClose()
            setLoading(true);
            let accountCardResponseOk = true;
            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeleteAccountCardsbyCaseId(client.caseId, accountCardIdToDelete, token);
            accountCardResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                toast.error(t('FailedToDeleteCustomerSupplier'));
            } else {
                await fetchEnums(user, token, client)
                handleReopenEdit(item);
                toast.success(t('CustomerSupplierDeletedSuccessfully'));
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const handleAddAccountCard = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId)) {
            return;
        }
        const Item = addEditableItem;
        handleClose();
        try {
            setLoading(true);
            const response = await EnumsService.AddAccountCard(client.caseId, accountCodeId, accountCodeName, companyId, palCode, token);
            if (response.ok) {
                const accountCodeId = await response.json();
                let selectedAccountCard = {
                    cardId: accountCodeId,
                    caseId: client.caseId,
                    accountCaseId: companyId,
                    name: accountCodeName,
                    palCode: palCode,
                    client: null
                };

                const newItem = {
                    ...Item,
                    accountCodeId: accountCodeId?.toString(),
                    accountCodeName: accountCodeName?.toString(),
                    accountCaseId: invoiceClientCaseId?.toString(),
                };
                setNewItem(newItem);
                setAccountCardOptions(prevCards => {
                    const updatedCards = [...prevCards, selectedAccountCard];
                    setIsUpdated(true);
                    return updatedCards;
                });

                toast.success(t('CustomerSupplierAddedSuccessfully'));
                setSelectedOption(selectedAccountCard ? selectedAccountCard : null);

            } else {
                const errorText = await response.text();
                console.error('Failed to edit user', errorText);
                toast.error(t('FailedToAddCustomerSupplier'));
            }
        } catch (error) {
            console.error('Error edit user:', error);
            toast.error(t('FailedToAddCustomerSupplier'));
        }
        setLoading(false);
        setPalCode('');
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };

    const handleSaveEditAccountCard = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId, true)) {
            return;
        }
        const Item = addEditableItem;
        handleClose();
        try {
            setLoading(true)

            const response = await EnumsService.UpdateAccountCard(client.caseId, oldSelectedAccountCardItem.cardId, oldSelectedAccountCardItem.name, accountCodeId, accountCodeName, companyId, palCode, token);
            if (response.ok) {
                const newItem = {
                    ...Item,
                    accountCodeId: accountCodeId?.toString(),
                    accountCodeName: accountCodeName?.toString(),
                    accountCaseId: invoiceClientCaseId?.toString(),
                };
                setNewItem(newItem);
                let selectedAccountCard = {
                    cardId: accountCodeId,
                    caseId: client.caseId,
                    accountCaseId: companyId,
                    name: accountCodeName,
                    palCode: palCode,
                    client: null
                };
                setAccountCardOptions(prevCards => {
                    const updatedCards = prevCards.filter(card => card.cardId !== selectedAccountCard.cardId);
                    updatedCards.push(selectedAccountCard);
                    setIsUpdated(true);
                    return updatedCards;
                });
                // handleReopenEdit(editedItem);
                toast.success(t('CustomerSupplierUpdatedSuccessfully'));
                setSelectedOption(selectedAccountCard ? selectedAccountCard : null);

            } else {
                const errorText = await response.text();
                console.error('FailedToUpdateCustomerSupplier', errorText);
                toast.error(t('FailedToUpdateCustomerSupplier'));
            }
        } catch (error) {
            console.error('FailedToUpdateCustomerSupplier:', error);

            toast.error(t('FailedToUpdateCustomerSupplier'));

        }
        setLoading(false);
        setPalCode('');
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
        setShowAccountCardInputsEdit(false);
    };

    const CustomPalCode = (props) => {
        return (
            <components.Option {...props}>
                <div style={{ textAlign: 'center', width: '100%' }}>{props.label}</div>
            </components.Option>
        );
    };
    const handlePrevious = () => {
        let newIndex = currentIndex - 1;

        while (newIndex >= 0) {
            const previousItem = filteredAndSortedData[newIndex];

            if (previousItem.id !== addEditableItem.id) {
                setCurrentItem(previousItem, newIndex);
                break;
            }
            newIndex -= 1;
        }
    };

    const handleNext = () => {
        let newIndex = currentIndex + 1;

        while (newIndex < filteredAndSortedData.length) {
            const nextItem = filteredAndSortedData[newIndex];

            if (nextItem.id !== addEditableItem.id) {
                setCurrentItem(nextItem, newIndex);
                break;
            }
            newIndex += 1;
        }
    };

    // The setCurrentItem function should mimic the handleEdit function
    const setCurrentItem = (item, index) => {
        let statusChecked = client.documentIds.checks.includes(item.id);
        setStatusCheck(!statusChecked);
        setItemEditing(true);
        item.docType = type;
        if (item.checks && item.checks.length > 0) {
            item.documentUrl = item.checks.map(check => check.documentUrl);
        } else {
            item.documentUrl = [];
        }
        setOpenPaymentAcceptanceCreateEditModal(true);
        setAddEditableItem({ ...item });
        setPaymentBeingEdited(true);
        const matchingAccountCard = accountCardOptions.find(card => card.cardId?.toString() === item.accountCodeId?.toString());
        if (matchingAccountCard) {
            setSelectedOption(matchingAccountCard);
        }
        setButtonDisabled(false);
        setCurrentIndex(index);
    };

    const checkIfExistingAccountCard = (accountCardOptions, cardId, accountCaseId, edit = false) => {
        const existingAccountCard = accountCardOptions.filter(card =>
            (card.accountCaseId === accountCaseId && accountCaseId) ||
            (card.cardId === cardId && cardId)
        );
        const isExist = existingAccountCard && (edit
            ? existingAccountCard.length > 1
            : existingAccountCard.length > 0);
        if (isExist) {
            toast.info(t('AccountCardExists', { accountCodeId: existingAccountCard.cardId }));
            return true;
        }
    }


    return (
        <>
            {/* signature modal */}
            {signatureReferenceInvoiceModalVisible && (
                <div className='create-modal-overlay' onClick={closeSignatureInvoiceModal}>
                    <div className="modal-background" >
                        <div className={`modal-Invoice-content ${selectedLanguage === 'he' || selectedLanguage === 'ar' ? 'rtl-text' : ''}`}
                            ref={signatureReferenceInvoiceModalVisibleRef}
                            style={{ maxHeight: '450px' }}>
                            <h2>{t('NewInvoiceSignature')}</h2>
                            <input
                                type="text"
                                placeholder={t('Reference')}
                                className="input-field"
                                value={invoiceReference}
                                onChange={(e) => setInvoiceReference(e.target.value)}
                            />
                            {!ReferenceInvoiceModalVisible &&
                                <>
                                    <SignaturePad ref={sigPad} canvasProps={{ className: 'signatureCanvas' }} onEnd={handleSignatureChange} />

                                    <button className='clientItemButton clearSignature ' onClick={clearSignature}>
                                        <img src={cancelIcon} alt="Income" className="clientItem-icon clientItemListBack" />
                                        {t('ClearSignature')}
                                    </button>
                                </>
                            }
                            {errorMessage && <p className="error-message-create-document">{errorMessage}</p>}
                            {okMessage && <p className="ok-message-create-document">{okMessage}</p>}
                            <div className="button-row">
                                <button className="create-buttons" onClick={handleSignatureReferenceSave} disabled={!signatureReferenceFilled}>
                                    {t('save')}
                                </button>
                                <button className="create-buttons" onClick={handleSignatureReferenceClose}>
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <DraggableModal
                bigIframeModalOpen={bigIframeModalOpen}
                openedIframeUrl={openedIframeUrl}
                handleCloseIframeModal={handleCloseIframeModal}

            />



            {

                openPaymentAcceptanceCreateEditModal &&
                <PaymentAcceptanceCreateEditModal
                    handleClose={handleClose}
                    t={t}
                    itemEditing={itemEditing}
                    addEditableItem={addEditableItem}
                    setPaymentDate={setPaymentDate}
                    setReportingMonthDate={setReportingMonthDate}
                    handleChangeAccountType={handleChangeAccountType}
                    handleDescriptionChange={handleDescriptionChange}
                    handleChangeOriginalTaxClearance={handleChangeOriginalTaxClearance}
                    handleAddEditableItemChange={handleAddEditableItemChange}
                    handleRadioChange={handleRadioChange}
                    handleAddItem={handleAddItem}
                    handleItemChange={handleItemChange}
                    handleRemoveItem={handleRemoveItem}
                    accountCardOptions={accountCardOptions}
                    handleSave={handleSave}
                    handleClickDoneCreation={handleClickDoneCreation}
                    buttonDisabled={buttonDisabled}
                    isClosedReportingMonthAndIsRange={isClosedReportingMonthAndIsRange}
                    isClosedReportingMonth={isClosedReportingMonth}
                    isRange={isRange}
                    paymentBeingEdited={paymentBeingEdited}
                    setAccount={setAccount}
                    showAccountCardInputsEdit={showAccountCardInputsEdit}
                    handleSaveEditAccountCard={handleSaveEditAccountCard}
                    handleAddAccountCard={handleAddAccountCard}
                    handleCloseAddNewAccountCardDocument={handleCloseAddNewAccountCardDocument}
                    handleAccountEdit={handleAccountEdit}
                    handleAccountDelete={handleAccountDelete}
                    handleMuiChange={handleMuiChange}
                    addNewAccountCardDocument={addNewAccountCardDocument}
                    companyId={companyId}
                    accountCodeName={accountCodeName}
                    accountCodeId={accountCodeId}
                    palCode={palCode}
                    setPalCode={setPalCode}
                    customStyles={customStyles}
                    client={client}
                    setLoading={setLoading}
                    handleTransferRefresh={handleTransferRefresh}
                    token={token}
                    statusCheck={statusCheck}
                    selectedOption={selectedOption}
                    type={type}
                    direction={direction}
                    setAddNewAccountCardDocument={setAddNewAccountCardDocument}
                    CustomPalCode={CustomPalCode}
                    handlePrevious={handlePrevious}
                    currentIndex={currentIndex}
                    filteredAndSortedData={filteredAndSortedData}
                    handleNext={handleNext}
                    showType={showType}
                />
            }
            {/* ask if you want to add receipt */}
            <Modal open={receiptQuestionModal} onClose={closeQuestionModal} >
                <div
                    // onClick={closeQuestionModal}
                    className='create-modal-overlay'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional for a dim background
                        flexDirection: 'column'
                    }}
                >
                    <div className='create-modal-overlay'
                        style={{
                            display: 'flex',
                            width: '20%',
                            height: '20%',
                            maxWidth: '600px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            position: 'relative',
                            overflowY: 'auto',
                            flexDirection: 'column'
                        }}>
                        <h2 style={{ marginBottom: '20px', fontSize: 'large' }}>{t('CreateReceipt')}</h2>
                        <Box>
                            <button className="create-buttons" onClick={() => openChooseModal()}>
                                {t('Yes')}
                            </button>
                            <button className="create-buttons" onClick={() => handleSaveNewReceipt()}>
                                {t('No')}
                            </button>
                        </Box>
                    </div>
                </div>
            </Modal >


            {/* invoice type */}
            <Modal Modal
                open={chooseInvoiceModal}
                onClose={closeCreateInvoiceModal}
                aria-labelledby="choose-invoice-modal-title"
                aria-describedby="choose-invoice-modal-description"
            >
                <div
                    className='create-modal-overlay'
                    onClick={closeCreateInvoiceModal}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional for a dim background
                        flexDirection: 'column' // optional for a dim background
                    }}
                >
                    <div
                        className="modal-background"
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            width: '20%',
                            height: '30%',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            position: 'relative',
                            overflowY: 'auto',
                            flexDirection: 'column',
                            gap: '10px'
                        }}
                    >
                        <h2 style={{ marginBottom: '50px' }} id="choose-invoice-modal-title">
                            {t('NewInvoice')}
                        </h2>
                        <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('Receipt')}>
                            {t('Receipt')}
                        </div>
                        <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('ReceiptTax')}>
                            {t('ReceiptTax')}
                        </div>
                    </div>
                </div>
            </Modal >


            {/* create invoice */}
            <Modal
                open={invoiceModalVisible}
                onClose={closeCreateInvoiceModal}
                aria-labelledby="create-invoice-modal-title"
                aria-describedby="create-invoice-modal-description"
            >
                <div
                    className='create-modal-overlay'
                    onClick={closeCreateInvoiceModal}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional for a dim background
                    }}
                >
                    <div
                        className="modal-background"
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            width: '50%',
                            height: '80%',
                            maxWidth: '600px',
                            backgroundColor: 'transparent',
                            borderRadius: '8px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            position: 'relative',
                            top: '50%',
                            transform: 'translateY(-60%)',
                        }}
                    >
                        <div className={`modal-Invoice-content ${selectedLanguage === 'he' || selectedLanguage === 'ar' ? 'rtl-text' : ''}`} style={{ direction: 'rtl' }} ref={modalInvoiceCreateDocumentRef}>
                            <h2 id="create-invoice-modal-title">{t(invoiceTypeInModal) + " " + t('newF')}</h2>

                            <input
                                type="text"
                                placeholder={t('EnterClientCaseId')}
                                className="input-field input-field_Container_required-asterisk"
                                value={invoiceClientCaseId}
                                onChange={(e) => setInvoiceClientCaseId(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder={t('PhoneNumber')}
                                className="input-field input-field_Container_required-asterisk"
                                value={invoiceClientPhoneNumber}
                                onChange={(e) => setInvoiceClientPhoneNumber(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder={t('EnterClientEmail')}
                                className="input-field"
                                value={invoiceClientEmail}
                                onChange={(e) => setInvoiceClientEmail(e.target.value)}
                            />

                            <input
                                type="text"
                                placeholder={t('EnterClientLocation')}
                                className="input-field"
                                value={invoiceClientLocation}
                                onChange={(e) => setInvoiceClientLocation(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder={t('enterDescription')}
                                className="input-field"
                                value={invoiceDescription}
                                onChange={(e) => setInvoiceDescription(e.target.value)}
                            />
                            {invoiceTypeInModal.toString() === 'ReceiptTax' && (
                                <div className='items_Container'>
                                    {/* Add button */}
                                    <button className='clientItemButton' onClick={handleAddInvoiceItem}>
                                        <img src={addIcon} alt="Income" className="clientItem-icon" />
                                        {t('AddItem')}
                                    </button>

                                    {/* Item inputs */}
                                    {invoiceItems.map((item, index) => (
                                        <div className='item_Container' key={index}>
                                            {addItemFlags[index] ? (
                                                <>
                                                    <button className='clientItemButton ' onClick={() => handleCloseAddItem(index)}>
                                                        <img src={backIcon} alt="Income" className="clientItem-icon clientItemListBack" />
                                                        {t('ReturnToItemList')}
                                                    </button>
                                                    <div className="input-field_Container">
                                                        <div className="input-field-title">{t('Makat')}</div>
                                                        <input
                                                            className="input-field input-field_Container_required-asterisk"
                                                            type="text"
                                                            placeholder={t('Makat')}
                                                            value={item.makat}
                                                            onChange={(e) => handleInvoiceItemChange(index, 'makat', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="input-field_Container">
                                                        <div className="input-field-title">{t('ItemDescription')}</div>
                                                        <input
                                                            className="input-field input-field_Container_required-asterisk"
                                                            type="text"
                                                            placeholder={t('ItemDescription')}
                                                            value={item.name}
                                                            onChange={(e) => handleInvoiceItemChange(index, 'name', e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <Select
                                                    className="input-field-dropdown"
                                                    placeholder={t('Item')}
                                                    options={clientItems.map(option => ({ value: option.makat, label: option.name }))}
                                                    value={clientItems
                                                        .filter(option => option.name === item.name)
                                                        .map(option => ({ value: option.makat, label: option.name }))[0]}
                                                    onChange={(selectedOption) => handleInvoiceItem(index, selectedOption)}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderColor: state.isFocused ? 'grey' : 'red',
                                                        }),
                                                    }}
                                                />
                                            )}
                                            <div className="input-field_Container">
                                                <div className="input-field-title">{t('Quantity')}</div>
                                                <input
                                                    type="number"
                                                    placeholder={t('Quantity')}
                                                    value={item.quantity}
                                                    onChange={(e) => handleInvoiceItemChange(index, 'quantity', e.target.value)}
                                                    className="input-field"
                                                />
                                            </div>
                                            <div className="input-field_Container">
                                                <div className="input-field-title">{t('Price')}</div>
                                                <input
                                                    type="number"
                                                    placeholder={t('Price')}
                                                    value={item.price}
                                                    onChange={(e) => handleInvoiceItemChange(index, 'price', e.target.value)}
                                                    className="input-field input-field_Container_required-asterisk"
                                                />
                                            </div>
                                            <div className="input-field_Container">
                                                <div className="input-field-title">{t('Discount')}</div>
                                                <input
                                                    type="number"
                                                    min={"0"}
                                                    max={"100"}
                                                    placeholder={t('Discount')}
                                                    value={item.discount}
                                                    onChange={(e) => handleInvoiceItemChange(index, 'discount', e.target.value)}
                                                    className="input-field input-field_Container_required-asterisk"
                                                />
                                            </div>
                                            <button className='clientItemCancelButton' onClick={() => handleRemoveInvoiceItem(index)}>
                                                <img src={cancelIcon} alt="Income" className="clientItem-icon" />
                                                {t('RemoveItem')}
                                            </button>
                                        </div>
                                    ))}
                                </div>)}
                            <div className='add-document-input-container '>
                                <div className='edit-checkBox-field'>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                        name="position"
                                        defaultValue="top"
                                        value={priceIncludingVAT.toString()}
                                        onChange={handlePriceIncludingVATChange}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            label={t('PriceIncludingVAT')}
                                            labelPlacement="top"
                                            checked={priceIncludingVAT}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            label={t('PriceExcludingVAT')}
                                            labelPlacement="top"
                                            checked={!priceIncludingVAT}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="date-section">
                                <div className="date-input-container">
                                    <ReactDatePicker
                                        selected={invoiceDate}
                                        onChange={(date) => handleSetInvoiceDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        className="date-input"
                                        customInput={<CustomInput />}
                                    />
                                    <img src={calendarIcon} alt="Calendar" className="calendar-icon" />
                                </div>
                            </div>
                            {errorMessage && <p className="error-message-create-document">{errorMessage}</p>}
                            {okMessage && <p className="ok-message-create-document">{okMessage}</p>}
                            {priceMatchProblemString !== '' && <h2 style={{ fontSize: 'medium', marginTop: '20px', color: 'red' }}>{priceMatchProblemString}</h2>}
                            <div className="button-row">


                                {invoiceTypeInModal.toString() === 'ReceiptTax' ?
                                    <button className="create-buttons" onClick={handleReceiptTaxSave} disabled={!allClientItemFieldsFilled}>
                                        {t('save')}
                                    </button>
                                    :
                                    <button className="create-buttons" onClick={handleCheckSign} disabled={!allFieldsCheckedInReceipt}>
                                        {t('save')}
                                    </button>
                                }
                                <button className="create-buttons" onClick={handleInvoiceClose}>
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails client={client} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                        <div className={`main-title ${direction}`}>{t(type)}</div>
                    </div>
                    <div className={`title-back-container ${direction}`} style={{ gap: '10px' }}>
                        {!isRange &&

                            <div className="MonthReportButton" onClick={() => {
                                setActionType(isClosedReportingMonth ? 'openMonth' : 'closeMonth');
                                setIsModalVisible(true);
                            }}>
                                {isClosedReportingMonth ? t("OpenMonthReport") : t("CloseMonthReport")}
                            </div>
                        }

                        {
                            (!isRange && !isClosedReportingMonth) &&
                            <>
                                <div className="create-selector" onClick={() => handleOpenCreateModal("Payment")}>
                                    {t('Payment')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenCreateModal("Approval")}>
                                    {t('Approval')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenReceiptTaxModal("Receipt")}>
                                    {t('Receipt')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenReceiptTaxModal("ReceiptTax")}>
                                    {t('ReceiptTax')}
                                </div>
                            </>

                        }



                    </div>

                    <div className={`searchFilter-bar__container ${direction}`} >
                        <ActionButton
                            variant="contained"
                            disabled={selectedRows.length === 0}
                            onClick={setOpenOption}
                            className='custom-input-box-sizing'
                        >
                            {t('OpenOptions')}
                        </ActionButton>
                        <TextField
                            fullWidth
                            placeholder={t('searchClient')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                            style={{ height: '55px' }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                        {isRange ? (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startDate)}
                                    label={t("StartDate")}
                                />

                                <FormControlLabel
                                    value={isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}
                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />

                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endDate)}
                                    label={t("EndDate")}
                                />
                            </>
                        ) : (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleSetSortDate(date)}
                                    date={dayjs(sortDate)}
                                    label={t("date")}
                                />

                                <FormControlLabel
                                    value={!isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}

                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />
                            </>
                        )}
                    </div>
                    <div className='document-Dense-container'  >

                        <DenseTable headers={denseTableCreditDebitHeader} rows={denseTableCreditDebitRows} width='100%' styleFlag

                            headerStyle={{
                                opacity: 0.6,
                                background: 'var(--orange, #F9AA2A)',
                                textAlign: 'center',
                                minHeight: '50px'
                            }}
                            rowStyle={{
                                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                                minHeight: '50px'
                            }}
                        />


                        <DenseTable headers={denseTableHeader} rows={denseTableRows} width='100%'

                            headerStyle={{
                                opacity: 0.6,
                                background: 'var(--orange, #F9AA2A)',
                                textAlign: 'center',
                                minHeight: '50px'
                            }}
                            rowStyle={{
                                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                                minHeight: '50px'
                            }}
                        />

                    </div>

                    {modalVisible && (
                        <div className='create-modal-overlay' onClick={closeCreateModal}>
                            <div className="modal-background">
                                <div className={`newDocument-modal-content ${direction}`} ref={modalCreateDocumentRef} style={{ width: '20%' }}>
                                    <h2 style={{ marginBottom: '20px' }}>{t('Options')}</h2>

                                    <div className="button-column" style={{ alignItems: 'center' }}>
                                        <button className={`newDocument-button ${chooseUploadDocument ? 'active' : ''}`} style={{ marginBottom: '10px' }} onClick={() => handleChooseUploadDocument(true)}>
                                            {t('Documents')}
                                        </button>
                                        <button className={`newDocument-button ${!chooseUploadDocument ? 'active' : ''}`} onClick={() => handleChooseUploadDocument(false)}>
                                            {t('NoDocuments')}
                                        </button>
                                        {chooseUploadDocument && (
                                            <>
                                                <div style={{ marginTop: '10px' }} className={`newDocument-file-upload-container ${chooseUploadDocument ? "input-field_Container_required-asterisk" : ""}`}>
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        className="input-file"
                                                        multiple
                                                        onChange={handleDocumentUpload}
                                                    />
                                                    <label htmlFor="file" className="newDocument-upload-label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="upload-icon" viewBox="0 0 58 58" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z" fill="#F9AA2A" />
                                                            <path d="M38.6673 21.75C36.9703 21.75 36.1218 21.75 35.5124 22.1572C35.2485 22.3336 35.0218 22.5602 34.8456 22.8241C34.4381 23.4336 34.4381 24.2822 34.4381 25.9792V36.25C34.4381 39.2529 32.0038 41.6875 29.0006 41.6875C25.9977 41.6875 23.5632 39.2529 23.5632 36.25V25.9792C23.5632 24.2822 23.5632 23.4335 23.1559 22.824C22.9796 22.5601 22.7531 22.3336 22.4893 22.1573C21.8797 21.75 21.0311 21.75 19.334 21.75C12.4986 21.75 9.08094 21.75 6.95746 23.8735C4.83398 25.9971 4.83398 29.4142 4.83398 36.2495V38.6662C4.83398 45.5015 4.83398 48.9191 6.95746 51.0427C9.08094 53.1662 12.4986 53.1662 19.334 53.1662H38.6673C45.5026 53.1662 48.9203 53.1662 51.0438 51.0427C53.1673 48.9191 53.1673 45.5015 53.1673 38.6662V36.2495C53.1673 29.4142 53.1673 25.9971 51.0438 23.8735C48.9203 21.75 45.5026 21.75 38.6673 21.75Z" fill="#F9AA2A" />
                                                        </svg>
                                                        <span className="upload-text">
                                                            {documents?.length > 0 ? documents.map(doc => doc.name).join(', ') : t('uploadDocument')}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="newDocument-button-container">
                                                    <div
                                                        className={`newDocument-create-buttons ${!(documents.length > 0) ? 'disabled' : ''}`}
                                                        onClick={() => handleOCRSave(documents)}
                                                        disabled={!(documents.length > 0)}
                                                    >
                                                        {t('save')}
                                                    </div>
                                                    <div className="newDocument-cancel-buttons" onClick={handleClose}>
                                                        {t('close')}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {errorMessage && <p className="error-message-create-document">{errorMessage}</p>}
                                        {okMessage && <p className="ok-message-create-document">{okMessage}</p>}


                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {receiptTaxModalVisible && (
                        <div className='create-modal-overlay' onClick={closeCreateModal}>
                            <div className="modal-background">
                                <div className={`newDocument-modal-content ${direction}`} ref={modalCreateDocumentRef} style={{ width: '20%' }}>
                                    <h2 style={{ marginBottom: '20px' }}>{t('Options')}</h2>

                                    <div className="button-column" style={{ alignItems: 'center' }}>
                                        <button className={`newDocument-button ${chooseUploadDocument ? 'active' : ''}`} style={{ marginBottom: '10px' }} onClick={() => setChooseUploadDocument(true)}>
                                            {t('Documents')}
                                        </button>
                                        <button className={`newDocument-button ${!chooseUploadDocument ? 'active' : ''}`} onClick={() => handleNavigateToScreen({})}>
                                            {t('NoDocuments')}
                                        </button>
                                        {chooseUploadDocument && (
                                            <>
                                                <div style={{ marginTop: '10px' }} className={`newDocument-file-upload-container ${chooseUploadDocument ? "input-field_Container_required-asterisk" : ""}`}>
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        className="input-file"
                                                        multiple
                                                        onChange={handleDocumentUpload}
                                                    />
                                                    <label htmlFor="file" className="newDocument-upload-label">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="upload-icon" viewBox="0 0 58 58" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z" fill="#F9AA2A" />
                                                            <path d="M38.6673 21.75C36.9703 21.75 36.1218 21.75 35.5124 22.1572C35.2485 22.3336 35.0218 22.5602 34.8456 22.8241C34.4381 23.4336 34.4381 24.2822 34.4381 25.9792V36.25C34.4381 39.2529 32.0038 41.6875 29.0006 41.6875C25.9977 41.6875 23.5632 39.2529 23.5632 36.25V25.9792C23.5632 24.2822 23.5632 23.4335 23.1559 22.824C22.9796 22.5601 22.7531 22.3336 22.4893 22.1573C21.8797 21.75 21.0311 21.75 19.334 21.75C12.4986 21.75 9.08094 21.75 6.95746 23.8735C4.83398 25.9971 4.83398 29.4142 4.83398 36.2495V38.6662C4.83398 45.5015 4.83398 48.9191 6.95746 51.0427C9.08094 53.1662 12.4986 53.1662 19.334 53.1662H38.6673C45.5026 53.1662 48.9203 53.1662 51.0438 51.0427C53.1673 48.9191 53.1673 45.5015 53.1673 38.6662V36.2495C53.1673 29.4142 53.1673 25.9971 51.0438 23.8735C48.9203 21.75 45.5026 21.75 38.6673 21.75Z" fill="#F9AA2A" />
                                                        </svg>
                                                        <span className="upload-text">
                                                            {documents?.length > 0 ? documents.map(doc => doc.name).join(', ') : t('uploadDocument')}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="newDocument-button-container">
                                                    <div
                                                        className={`newDocument-create-buttons ${!(documents.length > 0) ? 'disabled' : ''}`}
                                                        onClick={() => handleOCRSaveV2(documents)}
                                                        disabled={!(documents.length > 0)}
                                                    >
                                                        {t('save')}
                                                    </div>
                                                    <div className="newDocument-cancel-buttons" onClick={handleClose}>
                                                        {t('close')}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {errorMessage && <p className="error-message-create-document">{errorMessage}</p>}
                                        {okMessage && <p className="ok-message-create-document">{okMessage}</p>}


                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showDownloadModal && (
                        <div className="document-download-modal-overlay" onClick={closeDownloadModal}>
                            <div className="document-download-modal" onClick={(e) => e.stopPropagation()}>
                                <h4 className="download-report-title">{t('DownloadDocuments')}</h4>
                                <div className="document-download-modal-content">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className='download-document-input-field'
                                            value={downloadAmount || ''}
                                            onChange={(e) => setDownloadAmount(e.target.value)}
                                            placeholder={t('enterDocumentCost')}
                                        />
                                    </div>
                                    <Select
                                        classNamePrefix="download-my-select"
                                        placeholder={t('PaymentType')} // Added placeholder prop
                                        options={checkTypeOptions.map(option => ({ value: option.name, label: option.name }))}
                                        value={checkTypeOptions
                                            .filter(option => option.name === checkTypeOption)
                                            .map(option => ({ value: option.name, label: option.name }))[0]} // Ensure it's in the correct format
                                        onChange={(selectedOption) => setDownloadCheckType(selectedOption.value)}
                                    />
                                    <Select
                                        classNamePrefix="download-my-select"
                                        options={accountCardOptions.map(option => ({ value: option.cardId, label: option.name }))}
                                        value={accountCardOptions
                                            .filter(option => option.name === editedItem.accountCodeName)
                                            .map(option => ({ value: option.cardId, label: option.name }))[0]}
                                        onChange={(selectedOptions) => {
                                            const selectedValues = selectedOptions.map(option => option.value); // Extract the values
                                            setDownloadAccountCodeId(selectedValues); // Assuming setDownloadAccountCodeId expects an array of values
                                        }}
                                        isMulti
                                        placeholder={t('CheckTitle')}
                                    />

                                    <div className="download-document-date-picker">
                                        <label htmlFor="end-date" className="download-document-date-picker-label">{t("To")}</label>
                                        <ReactDatePicker
                                            id="end-date"
                                            selected={downloadEndDate}
                                            onChange={handleDownloadEndDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM-yyyy"
                                            className="date-input"
                                            customInput={<CustomDownloadInput placeholderText={t('SelectEndDate')} />}
                                        />
                                    </div>
                                    {/* From Date Picker */}
                                    <div className="download-document-date-picker">
                                        <label htmlFor="start-date" className="download-document-date-picker-label">{t("From")}</label>
                                        <ReactDatePicker
                                            id="start-date"
                                            selected={downloadStartDate}
                                            onChange={handleDownloadStartDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM-yyyy"
                                            className="date-input"
                                            customInput={<CustomDownloadInput placeholderText={t('SelectStartDate')} />}

                                        />
                                    </div>
                                </div>
                                <div className="download-button-row">
                                    <button className="download-create-buttons" onClick={handleDownloadDocuments} >
                                        {t('Download')}
                                    </button>
                                    <button className="download-create-buttons" onClick={() => setShowDownloadModal(false)}>
                                        {t('close')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <Dialog open={openOptions} onClose={closeOptions}>
                        <StyledDialogTitle>{t('SelectOption')}</StyledDialogTitle>
                        <StyledDialogContent>
                            <Typography variant="body2" color="#025366">{t('PleaseSelectOptionForSelectedRows')}</Typography>
                            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" mt={2}>
                                <OptionButton onClick={() => handleOptionSelect('notDone')}>
                                    {t('unprocessed')}
                                </OptionButton>
                                <OptionButton onClick={() => handleOptionSelect('done')}>
                                    {t('processed')}
                                </OptionButton>
                                {/* Add more OptionButtons here if needed */}
                            </Box>
                        </StyledDialogContent>
                        <StyledDialogActions>
                            <StyledButton onClick={closeOptions}>
                                {t('close')}
                            </StyledButton>
                        </StyledDialogActions>
                    </Dialog>



                    <PaymentAcceptanceTable
                        filteredAndSortedData={filteredAndSortedData}
                        client={client}
                        isSelected={isSelected}
                        handleSelectRow={handleSelectRow}
                        handleSelectAllClick={handleSelectAllClick}
                        handleEdit={handleEdit}
                        handleDeleteClick={handleDeleteClick}
                        openPdfInNewTab={openPdfInNewTab}
                        accountCardMap={accountCardMap}
                        headerStyle={headerStyle}
                        handleSortChange={handleSortChange}
                        sortConfig={sortConfig}
                        isClosedReportingMonth={isClosedReportingMonth}
                        isRange={isRange}
                        direction={direction}
                        handleSelectRows={handleSelectRows}
                        setSelectedRows={setSelectedRows}
                        selectedRows={selectedRows}
                        setIsAllSelected={setIsAllSelected}
                        isAllSelected={isAllSelected}
                        handleRightClickAction={handleRightClickAction}
                    />
                </div>
                <HamburgerMenu client={client} />
                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    onDelete={handleConfirm}
                    confirmMessage={getConfirmMessage()}
                    titleMessage={getTitleMessage()}
                />
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}



            </div>
        </>

    );
}


export default PaymentAcceptance;
