import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Dropdown } from 'primereact/dropdown';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect.jsx';
import { useTranslation } from 'react-i18next';
import AddAccountCardModal from '../Widgets/AddAccountCardModal/AddAccountCardModal'; // Import AddAccountCardModal
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Autocomplete,
    Popper,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6', // Hover color
        cursor: 'pointer', // Changes the cursor to pointer
    },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #F9AA2A) !important',
        },
    },
}));
const AddOrEditBankDialog = ({
    open,
    onClose,
    isEditingBank,
    selectedBankForCreate,
    bankOptions,
    handleChangeBankForCreate,
    bankBranch,
    handleChangeCreateBank,
    bankAccountNumber,
    cardForNewBank,
    processedCardsData,
    setCardForNewBank,
    handleAddOrEditBank,
    handleRemoveBank,
    client,
    token,
    setLoading,
    palCodes,
    fetchEnums,
}) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

    // State variables for adding/editing account cards
    const [creditDebitSearchTerm, setCreditDebitSearchTerm] = useState('');
    const [newAccountCard, setNewAccountCard] = useState({ accountCodeName: '', accountCodeId: '' });
    const [isEditAccountCard, setIsEditAccountCard] = useState(false);
    const [isAddAccountCard, setIsAddAccountCard] = useState(false);
    const [palCode, setPalCode] = useState('בנקים');
    const [newCardId, setNewCardId] = useState(null);
    const [addAccountCardType, setAddAccountCardType] = useState('cardForNewBank');
    const [cardIdOption, setCardIdOption] = useState(-1);
    
    // Process account card options for Autocomplete
    const formattedAccountCardOptions = processedCardsData.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option?.accountCaseId || ''}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
        palCode: option.palCode,
        isEditable: option.isEditable
    }));

    // Determine next card ID for new account cards
    useEffect(() => {
        if (processedCardsData && processedCardsData.length > 0) {
            const maxCardId = Math.max(
                ...processedCardsData.slice(1).map((option) => parseInt(option.cardId, 10))
            );
            
            
            setCardIdOption(maxCardId + 1);
        }
    }, [processedCardsData]);

    // Handle updating the card after adding a new account card
    const handleChangeAfterAdd = (cardId, accountType) => {
        const newCard = {
            cardId,
            type: accountType,
        };
        setNewCardId(newCard);
    };

    // Update the selected account card after adding a new one
    useEffect(() => {
        if (processedCardsData && processedCardsData.length > 0 && newCardId) {
            const selectedOption = processedCardsData.find(
                (option) => option.cardId === newCardId.cardId
            );
            if (newCardId.type === 'cardForNewBank') {
                setCardForNewBank(selectedOption);
            }
            setNewCardId(null);
        }
    }, [processedCardsData, newCardId]);

    // Handle editing an existing account card
    const EditAccountCard = (selectedItem, accountType) => {
        if (!selectedItem) {
            console.warn('No account card selected for editing.');
            return;
        }
        setPalCode(selectedItem.palCode);
        setNewAccountCard({
            accountCodeName: selectedItem.name || '',
            accountCodeId: selectedItem.cardId,
            accountCaseId: selectedItem.accountCaseId || '',
        });
        setIsEditAccountCard(true);
        setAddAccountCardType(accountType);
        setIsAddAccountCard(true);
    };

    // Handle selecting an account card or adding a new one
    const handleCreditDebitForJournalEntry = (newValue, accountType) => {
        if (!newValue) {
            setCardForNewBank(null);
            return;
        }
        
        if (newValue.value1 === 'AddItem') {
            setIsEditAccountCard(false);
            setIsAddAccountCard(true);
            setNewAccountCard({ accountCodeName: newValue.searchTerm || '', accountCodeId: cardIdOption });
        } else {
            // Update cardForNewBank
            const selectedOption = formattedAccountCardOptions.find(
                (option) => option.cardId === newValue.cardId
            );
            setCardForNewBank(selectedOption);
        }
    };

    // Handle adding a new account card when no options match
    const handleNoOptionCreditDebitClick = (searchTerm, accountType) => {
        const newOption = {
            searchTerm: searchTerm,
            value1: 'AddItem',
        };
        handleCreditDebitForJournalEntry(newOption, accountType);
    };
   
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ zIndex: 1300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            PaperProps={{
                style: {
                    padding: '20px',
                    width: 'auto',
                    maxWidth: '400px',
                    margin: 'auto'
                }
            }}
        >

            <DialogTitle sx={{ textAlign: 'center' }}>
                <div className="form-row-item-title">
                    {isEditingBank ? t('EditBankAccount') : t('SetBank')}
                </div>
            </DialogTitle>
            <DialogContent sx={{ direction: 'ltr', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {/* Bank Dropdown */}
                <div className="bank-form-row-item">
                    <div className="form-row-item-title">
                        {t('SelectABank')}<span className="required-asterisk">*</span>
                    </div>
                    <AutocompleteSelect
                        options={bankOptions}
                        selectedValues={selectedBankForCreate}
                        onChange={(event, value) => handleChangeBankForCreate(value)}
                        // label={t("SelectABank")}
                        placeholder={t("SelectABank")}
                        isMultiple={false}
                        width="100%"
                    />
                </div>


                <div className="bank-form-row-item">
                    <div className="form-row-item-title">
                        {t('BankBranch')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('BankBranch')}
                        value={bankBranch}
                        onChange={(e) => handleChangeCreateBank('bankBranch', e.target.value)}
                        className="add-client-input-style"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>


                {/* Bank Account Number Input */}
                {/* TextField for Bank Account Number */}
                <div className="bank-form-row-item">
                    <div className="form-row-item-title">
                        {t('BankNumber')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('BankNumber')}
                        value={bankAccountNumber}
                        onChange={(e) => handleChangeCreateBank('bankAccountNumber', e.target.value)}
                        className="add-client-input-style"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>


                <div className="bank-form-row-item">
                    <div className="form-row-item-title">
                        {t('AccountCard')}
                        <span className="required-asterisk">*</span>
                    </div>
                    {/* <AutocompleteSelect
                        options={processedCardsData}
                        selectedValues={cardForNewBank ? processedCardsData.find(card => card.cardId === cardForNewBank) : null}
                        onChange={(event, value) => setCardForNewBank(value)}
                        // label={t("SelectABank")}
                        
                        placeholder={t("AccountCard")}
                        isMultiple={false}
                        width="100%"
                    /> */}
                    <Autocomplete
                        options={formattedAccountCardOptions}
                        getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                        value={cardForNewBank || null}
                        onChange={(event, newValue) => {
                            handleCreditDebitForJournalEntry(newValue, 'cardForNewBank');
                        }}
                        onInputChange={(event, inputValue) => {
                            setCreditDebitSearchTerm(inputValue);
                        }}
                        noOptionsText={
                            <Button
                                onClick={() =>
                                    handleNoOptionCreditDebitClick(creditDebitSearchTerm, 'cardForNewBank')
                                }
                                variant="contained"
                                color="primary"
                            >
                                {t('AddAccountCard')} "{creditDebitSearchTerm}"
                            </Button>
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('AccountCard')}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                                        }`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        )}
                        renderOption={(props, option) => (
                            <Tooltip
                                title={
                                    option.isEditable ? t('RightClickToEdit') : t('NotEditable')
                                }
                                placement="top"
                            >
                                <StyledListItem
                                    {...props}
                                    onContextMenu={(event) => {
                                        if (!option.isEditable) {
                                            event.preventDefault();
                                            return;
                                        }
                                        event.preventDefault();
                                        EditAccountCard(option, 'cardForNewBank');
                                    }}
                                >
                                    {`${option?.cardId || ''} - ${option.name}`}
                                </StyledListItem>
                            </Tooltip>
                        )}
                        PopperComponent={(props) => (
                            <StyledPopper
                                {...props}
                                style={{
                                    ...props.style,
                                    direction: direction,
                                }}
                            />
                        )}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: '16px' }}>
                <Button
                    variant="contained"
                    onClick={() => handleAddOrEditBank()}
                    startIcon={<AddCircleOutlineIcon sx={{ ml: 1 }} />}
                    sx={{
                        backgroundColor: '#304FFF',
                        color: '#fff',
                        margin: '10px 0 0 0',
                        '&:hover': { backgroundColor: '#F9AA2A' },
                    }}
                >
                    {isEditingBank ? t('EditBankAccount') : t('SetBank')}
                </Button>
                {isEditingBank && (
                    <Button
                        variant="contained"
                        onClick={() => handleRemoveBank()} // Assuming a function that handles the remove action
                        startIcon={<DeleteIcon />}
                        sx={{
                            backgroundColor: '#ff1744', // Red color for remove button
                            color: '#fff',
                            margin: '10px 0 0 10px',
                            '&:hover': { backgroundColor: '#d50000' }
                        }}
                    >
                        {t('Remove')}
                    </Button>
                )}
            </DialogActions>
            <AddAccountCardModal
                accountCardOptions={processedCardsData}
                open={isAddAccountCard}
                onClose={() => setIsAddAccountCard(false)}
                editedItem={newAccountCard}
                isEdit={isEditAccountCard}
                direction={direction}
                client={client}
                token={token}
                setLoading={setLoading}
                handleChangeAfterAdd={handleChangeAfterAdd}
                fetchEnums={fetchEnums}
                type={addAccountCardType}
                palCodes={palCodes}
                propPalCode={palCode}
                setPalCode={setPalCode}
            />

        </Dialog>
    );
};

export default AddOrEditBankDialog;
