import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}`;

const GetNotMatchedbyDateRange = async ( CaseId, date, EndDate,accountCodeId, auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactions/byDateRangeAccountCodeId`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, EndDate,accountCodeId })
    });
    
    return response;
};


const GetMatchedbyTransactionDateRange = async ( CaseId, StartDate,EndDate,accountCodeId ,auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactionMatches/GetByTransactionDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, StartDate, EndDate,accountCodeId })
    });

    return response;
};


const GetMatchedbyDateRange = async ( CaseId, StartDate,EndDate,accountCodeId, IsMatchDate,auth) => {
    
    const response = await fetch(`${API_URL}/ExternalTransactionMatches/GetByMatchDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, StartDate, EndDate,accountCodeId,IsMatchDate })
    });

    return response;
};



const ImportExternalTransactions = async (CaseId, AccountCodeId, AccountCodeName, BankType, File, auth) => {
    const formData = new FormData();
    formData.append('CaseId', CaseId);
    formData.append('AccountCodeId', AccountCodeId);
    formData.append('AccountCodeName', AccountCodeName);
    formData.append('BankType', BankType);
    formData.append('File', File); 
    const response = await fetch(`${API_URL}/ExternalTransactions/Import`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth
        },
        body: formData
    });

    return response; // Directly return the parsed JSON response
};

const CreateMatch = async (matchDTO, auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactionMatches/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(matchDTO)
    });

    return response; // Optionally return the parsed response
};

const CreateTransaction = async (matchDTO, auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactions/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(matchDTO)
    });

    return response; // Optionally return the parsed response
};

const createMultipleMatches = async (matchDTOs, token) => {

    const response = await fetch(`${API_URL}/ExternalTransactionMatches/CreateMultiple`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(matchDTOs)
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to create matches');
    }

    return await response.json(); // Return parsed response if needed
};

const DeleteMatch = async (deleteDTO, auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactionMatches/Delete`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(deleteDTO)
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to delete the match');
    }

    return  response; // Return parsed response if needed
};

const GetTransactionSummaryByCaseAndAccount = async (CaseId, AccountCodeId, auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactions/GetTransactionSummaryByCaseAndAccount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, AccountCodeId })
    });

    return response;
};

const DeleteMultipleExternalTransactions = async (caseId, transactionIds, auth) => {
    const response = await fetch(`${API_URL}/ExternalTransactions/DeleteMultiple`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId: caseId, TransactionIds: transactionIds })
    });

    return response;
};



const ExternalTransactionsService = {
    ImportExternalTransactions,
    GetMatchedbyDateRange,
    GetMatchedbyTransactionDateRange,
    GetNotMatchedbyDateRange,
    CreateMatch,
    createMultipleMatches,
    DeleteMatch,
    CreateTransaction,
    GetTransactionSummaryByCaseAndAccount,
    DeleteMultipleExternalTransactions

}

export default ExternalTransactionsService;
