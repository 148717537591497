import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    Autocomplete,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Tooltip,
    IconButton,
    Portal,
    Button
} from '@mui/material';
import Draggable from 'react-draggable';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import addIcon from '../../../assests/images/Icons/addIcon.svg';
import cancelIcon from '../../../assests/images/Icons/cancelIcon.svg';
import DocumentTransferDialog from '../../DocumentTransferDialog/DocumentTransferDialog';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Constants from '../../../assests/Constants/constants';
const PaymentAcceptanceCreateEditModal = ({
    openInvoiceCreatorModal,
    handleClose,
    t,
    itemEditing,
    addEditableItem,
    setPaymentDate,
    setReportingMonthDate,
    handleChangeAccountType,
    handleDescriptionChange,
    handleChangeOriginalTaxClearance,
    handleAddEditableItemChange,
    handleRadioChange,
    handleAddItem,
    handleItemChange,
    handleRemoveItem,
    accountCardOptions,
    handleSave,
    handleClickDoneCreation,
    buttonDisabled,
    isClosedReportingMonthAndIsRange,
    isClosedReportingMonth,
    isRange,
    paymentBeingEdited,
    setAccount,
    showAccountCardInputsEdit,
    handleSaveEditAccountCard,
    handleAddAccountCard,
    handleCloseAddNewAccountCardDocument,
    handleAccountEdit,
    handleAccountDelete,
    handleMuiChange,
    addNewAccountCardDocument,
    companyId,
    accountCodeName,
    accountCodeId,
    palCode,
    setPalCode,
    customStyles,
    client,
    setLoading,
    handleTransferRefresh,
    token,
    statusCheck,
    selectedOption,
    type,
    direction,
    setAddNewAccountCardDocument,
    handlePrevious,
    handleNext,
    currentIndex,
    filteredAndSortedData,
    NoDocumentsPdf,
    showIframe = true,
    fetchEnums,
    showType = { type: 'all', index: -1 },
}) => {
    const { type: showTypeType, index: showTypeIndex } = showType;

    const [searchTerm, setSearchTerm] = useState('');
    const [container] = useState(() => document.createElement('div'));
    const nodeRef = useRef(null);

    const inputPropsStyle = {
        disableUnderline: true,
        className: 'custom-box-sizing-border-box rtl-input',
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
            '& *': {
                boxSizing: 'border-box !important',
            },
        },
    };

    const inputStyle = {
        style: { textAlign: 'right' },
    };

    const formattedAccountCardOptions = accountCardOptions?.map((option) => ({
        value: option.cardId,
        label: option.name,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
    }));

    const paymentTypeOptions = [
        { value: t('Approval'), label: t('Approval') },
        { value: t('Payment'), label: t('Payment') },
    ];

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            try {
                document.body.removeChild(container);
            } catch (e) {
                // Ignore
            }
        };
    }, [container]);

    const handleNoOptionClick = (searchTerm) => {
        if (formattedAccountCardOptions.length > 0) {
            const value = {
                ...formattedAccountCardOptions[0],
                searchTerm,
            };
            handleMuiChange(null, value);
        }
    };

    const renderItem = (itemType, item, index,showRemove = true) => {
        const paymentType = addEditableItem.paymentAcceptanceType;
        const isDisabled =
            !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth;

        const accountFieldLabel =
            paymentType === 'תשלום' ? t('Credit Account') : t('Debit Account');

        const accountFieldValue = paymentType === 'תשלום' ? item.creditAccount : item.debitAccount;

        return (
            <div
                className="item_Container"
                key={index}
                style={{ textAlign: 'right', direction: 'rtl' }}
            >
                {/* Common Fields */}
                {['checks', 'creditCards', 'cash', 'bankTransactions'].includes(itemType) && (
                    <>
                        {/* Description or Specific Field */}
                        {itemType === 'checks' && (
                            <>
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('CheckNumber')}</div>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        placeholder={t('CheckNumber')}
                                        value={item.description || ''}
                                        onChange={(e) =>
                                            handleItemChange('checks', index, 'description', e.target.value)
                                        }
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabled}
                                    />
                                </div>
                                {/* Bank Name */}
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('BankName')}</div>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        placeholder={t('BankName')}
                                        value={item.bankName || ''}
                                        onChange={(e) =>
                                            handleItemChange('checks', index, 'bankName', e.target.value)
                                        }
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabled}
                                    />
                                </div>
                                {/* Bank Code */}
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('BankCode')}</div>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        placeholder={t('BankCode')}
                                        value={item.bankCode || ''}
                                        onChange={(e) =>
                                            handleItemChange('checks', index, 'bankCode', e.target.value)
                                        }
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabled}
                                    />
                                </div>
                                {/* Bank Branch Code */}
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('BankBranchCode')}</div>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        placeholder={t('BankBranchCode')}
                                        value={item.bankBranchCode || ''}
                                        onChange={(e) =>
                                            handleItemChange('checks', index, 'bankBranchCode', e.target.value)
                                        }
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabled}
                                    />
                                </div>
                                {/* Supplier Account Number */}
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('SupplierAccountNumber')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        placeholder={t('SupplierAccountNumber')}
                                        value={item.supplierAccountNumber || ''}
                                        onChange={(e) =>
                                            handleItemChange(
                                                'checks',
                                                index,
                                                'supplierAccountNumber',
                                                e.target.value
                                            )
                                        }
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabled}
                                    />
                                </div>
                            </>
                        )}
                        {itemType !== 'checks' && (
                            <div className="newDocument-form-row-item">
                                <div className="edit-form-row-item-title">{t('Description')}</div>
                                <TextField
                                    fullWidth
                                    type="text"
                                    placeholder={t('Description')}
                                    value={item.description || ''}
                                    onChange={(e) =>
                                        handleItemChange(itemType, index, 'description', e.target.value)
                                    }
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    disabled={isDisabled}
                                />
                            </div>
                        )}
                        {/* Amount */}
                        <div className="newDocument-form-row-item">
                            <div className="edit-form-row-item-title">{t('Amount')}</div>
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('Amount')}
                                value={item.amount || ''}
                                onChange={(e) =>
                                    handleItemChange(itemType, index, 'amount', e.target.value)
                                }
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        </div>
                        {/* Date */}
                        <div className="newDocument-form-row-item">
                            <div className="edit-form-row-item-title">{t('date')}</div>
                            <CustomDatePicker
                                date={item.paymentDate ? new Date(item.paymentDate) : null}
                                onDateChange={(date) =>
                                    handleItemChange(itemType, index, 'paymentDate', date)
                                }
                                height="48px"
                                disabled={isDisabled}
                            />
                        </div>
                        {/* Credit/Debit Account */}
                        <div className="newDocument-form-row-item">
                            <div className="edit-form-row-item-title">{accountFieldLabel}</div>
                            <Autocomplete
                                options={formattedAccountCardOptions}
                                getOptionLabel={(option) => option.label}
                                value={
                                    formattedAccountCardOptions.find(
                                        (option) => option.value === accountFieldValue
                                    ) || null
                                }
                                onChange={(e, selectedOption) => {
                                    const accountField =
                                        paymentType === 'תשלום' ? 'creditAccount' : 'debitAccount';
                                    handleItemChange(
                                        itemType,
                                        index,
                                        accountField,
                                        selectedOption ? selectedOption.value : ''
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                        inputProps={{ ...params.inputProps, ...inputStyle }}
                                        placeholder={accountFieldLabel}
                                    />
                                )}
                                PopperComponent={(props) => (
                                    <Popper
                                        {...props}
                                        style={{
                                            ...props.style,
                                            direction: direction,
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                                        }}
                                    />
                                )}
                                disabled={isDisabled}
                            />
                        </div>
                        {(!isClosedReportingMonthAndIsRange && showRemove) && (
                            <div className="newDocument-form-row-item">
                                <button
                                    className="clientItemButton"
                                    style={{ width: '100%', marginTop: '20px' }}
                                    onClick={() => handleRemoveItem(itemType, index)}
                                >
                                    <img src={cancelIcon} alt="Income" className="clientItem-icon" />
                                    {t('RemoveItem')}
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    const renderItemsOfType = (type) => {
        let items = [];
        let itemType = '';

        switch (type) {
            case 'checks':
                items = addEditableItem?.checks || [];
                itemType = 'checks';
                break;
            case 'creditCards':
                items = addEditableItem?.creditCards || [];
                itemType = 'creditCards';
                break;
            case 'cash':
                items = addEditableItem?.cash || [];
                itemType = 'cash';
                break;
            case 'bankTransactions':
                items = addEditableItem?.bankTransactions || [];
                itemType = 'bankTransactions';
                break;
            default:
                items = [];
                break;
        }

        if (showTypeIndex === -1) {
            return items.map((item, index) => renderItem(itemType, item, index));
        } else {
            const item = items[showTypeIndex];
            if (item) {
                return renderItem(itemType, item, showTypeIndex,false);
            } else {
                return null;
            }
        }
    };

    return (
        <Portal container={container}>
            <Draggable handle=".handle" nodeRef={nodeRef}>
                <div
                    ref={nodeRef}
                    style={{
                        position: 'absolute',
                        top: '10%',
                        left: '30%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        width: '600px',
                        zIndex: 1300,
                        display: 'flex',
                        flexDirection: 'row',
                        direction: direction,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #304fff',
                    }}
                >

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        {/* Header */}
                        <div
                            className="handle"
                            style={{
                                cursor: 'move',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                marginBottom: '16px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                className="edit-title-container"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    margin: '0 auto',
                                }}
                            >
                                {itemEditing &&
                                    currentIndex !== filteredAndSortedData.length - 1 && (
                                        <div
                                            className={`backIconBackground ${currentIndex ===
                                                filteredAndSortedData.length - 1
                                                ? 'pointer-events-disable'
                                                : ''
                                                }`}
                                            onClick={handleNext}
                                        >
                                            {/* Next arrow SVG */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M9.00047 15.875L12.8805 11.995L9.00047 8.11498C8.61047 7.72498 8.61047 7.09498 9.00047 6.70498C9.39047 6.31498 10.0205 6.31498 10.4105 6.70498L15.0005 11.295C15.3905 11.685 15.3905 12.315 15.0005 12.705L10.4105 17.295C10.0205 17.685 9.39047 17.685 9.00047 17.295C8.62047 16.905 8.61047 16.265 9.00047 15.875Z"
                                                    fill="black"
                                                    fillOpacity="0.9"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                <div className="edit-title">
                                    {itemEditing
                                        ? `${t('EditReceiptPayment')} ${addEditableItem.uploadNumber
                                        }`
                                        : t('ApprovalPaymentUpload')}
                                </div>
                                {itemEditing && currentIndex !== 0 && (
                                    <div
                                        className={`backIconBackground ${currentIndex === 0
                                            ? 'pointer-events-disable'
                                            : ''
                                            }`}
                                        onClick={handlePrevious}
                                    >
                                        {/* Previous arrow SVG */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M14.9995 15.875L11.1195 11.995L14.9995 8.11498C15.3895 7.72498 15.3895 7.09498 14.9995 6.70498C14.6095 6.31498 13.9795 6.31498 13.5895 6.70498L8.99953 11.295C8.60953 11.685 8.60953 12.315 8.99953 12.705L13.5895 17.295C13.9795 17.685 14.6095 17.685 14.9995 17.295C15.3795 16.905 15.3895 16.265 14.9995 15.875Z"
                                                fill="black"
                                                fillOpacity="0.9"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                        {showTypeType === 'all' ? (
                            <>
                                <div
                                    style={{
                                        width: '95%',
                                        height: '80%',
                                        overflowY: 'auto',
                                        padding: '0 20px',
                                    }}
                                    className={`${direction}`}
                                >
                                    {/* Add New Account Card Document */}
                                    {addNewAccountCardDocument ? (
                                        <div className="items_Container" style={{ direction: 'rtl' }}>
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('CompanyId')}</div>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    placeholder={t('CompanyId')}
                                                    value={companyId}
                                                    onChange={(e) => setAccount('companyId', e.target.value)}
                                                    variant="standard"
                                                    InputProps={inputPropsStyle}
                                                    inputProps={inputStyle}
                                                />
                                            </div>
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('Card Name')}</div>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    placeholder={t('Card Name')}
                                                    value={accountCodeName}
                                                    onChange={(e) => setAccount('accountCodeName', e.target.value)}
                                                    variant="standard"
                                                    InputProps={inputPropsStyle}
                                                    inputProps={inputStyle}
                                                />
                                            </div>
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('Card ID')}</div>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    placeholder={t('Card ID')}
                                                    value={accountCodeId}
                                                    onChange={(e) => setAccount('accountCodeId', e.target.value)}
                                                    variant="standard"
                                                    InputProps={inputPropsStyle}
                                                    inputProps={inputStyle}
                                                />
                                            </div>
                                            {/* PalCode Autocomplete */}
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('PalCode')}</div>
                                                <Autocomplete
                                                    options={Constants.AccountCardPalCode}
                                                    getOptionLabel={(option) => option.label}
                                                    value={
                                                        Constants.AccountCardPalCode.find((option) => option.value === palCode) ||
                                                        null
                                                    }
                                                    onChange={(e, newValue) => setPalCode(newValue ? newValue.value : '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                                            inputProps={{ ...params.inputProps, ...inputStyle }}
                                                            placeholder={t('PalCode')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="newDocument-button-container">
                                                {(isRange
                                                    ? !addEditableItem?.isClosedReportingMonth
                                                    : !isClosedReportingMonth) && (
                                                        <button
                                                            disabled={!accountCodeName}
                                                            className="newDocument-create-buttons"
                                                            onClick={
                                                                showAccountCardInputsEdit
                                                                    ? handleSaveEditAccountCard
                                                                    : handleAddAccountCard
                                                            }
                                                        >
                                                            {t('save')}
                                                        </button>
                                                    )}
                                                <button
                                                    className="newDocument-cancel-buttons"
                                                    onClick={handleCloseAddNewAccountCardDocument}
                                                >
                                                    {t('close')}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="newDocument-form-row-item">
                                            <div className="edit-form-row-item-title">
                                                {type === 'Incomes' ? t('Client Name') : t('Provider Name')}
                                                <span className="required-asterisk">*</span>
                                            </div>
                                            <Autocomplete
                                                options={formattedAccountCardOptions}
                                                getOptionLabel={(option) => option.label}
                                                value={
                                                    formattedAccountCardOptions.find(
                                                        (option) => option.value === addEditableItem.accountCodeId
                                                    ) || null
                                                }
                                                onChange={(event, newValue) => handleMuiChange(event, newValue)}
                                                onInputChange={(event, inputValue) => {
                                                    setSearchTerm(inputValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={
                                                            type === 'Incomes' ? t('Client Name') : t('Provider Name')
                                                        }
                                                        variant="standard"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            ...inputPropsStyle,
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            ...inputStyle,
                                                        }}
                                                        required
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props}>{option.label}</li>
                                                )}
                                                PopperComponent={(props) => (
                                                    <Popper
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            direction: direction,
                                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                                                        }}
                                                    />
                                                )}
                                                noOptionsText={
                                                    <Button
                                                        onClick={() => handleNoOptionClick(searchTerm)}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t('AddAccountCard')} "{searchTerm}"
                                                    </Button>
                                                }
                                            />
                                        </div>
                                    )}

                                    {/* Payment Type */}
                                    <div className="newDocument-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('PaymentType')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <Autocomplete
                                            options={paymentTypeOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                paymentTypeOptions.find(
                                                    (option) => option.value === addEditableItem.paymentAcceptanceType
                                                ) || null
                                            }
                                            onChange={(e, newValue) =>
                                                handleChangeAccountType(newValue ? newValue : '')
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                                    inputProps={{ ...params.inputProps, ...inputStyle }}
                                                    placeholder={t('SelectPaymentType')}
                                                />
                                            )}
                                            PopperComponent={(props) => (
                                                <Popper
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        direction: direction,
                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                                                    }}
                                                />
                                            )}
                                            disabled={
                                                !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                            }
                                        />
                                    </div>
                                    {/* Document Date */}
                                    <div className="newDocument-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('Document Date')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <CustomDatePicker
                                            date={addEditableItem.date ? new Date(addEditableItem.date) : null}
                                            onDateChange={setPaymentDate}
                                            height="48px"
                                            disabled={
                                                !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                            }
                                        />
                                    </div>
                                    {/* Reporting Month Date */}
                                    {itemEditing && (
                                        <div className="newDocument-form-row-item">
                                            <div className="edit-form-row-item-title">
                                                {t('MonthReportDate')}
                                                <span className="required-asterisk">*</span>
                                            </div>
                                            <CustomDatePicker
                                                date={
                                                    addEditableItem.reportingMonthDate
                                                        ? new Date(addEditableItem.reportingMonthDate)
                                                        : null
                                                }
                                                onDateChange={(e) => setReportingMonthDate(e, 'reportingMonthDate')}
                                                height="48px"
                                                showMonthYearPicker
                                                disabled={
                                                    !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                                }
                                            />
                                        </div>
                                    )}
                                    {/* Description */}
                                    <div className="newDocument-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('Description')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            placeholder={t('Description')}
                                            value={addEditableItem.description || ''}
                                            onChange={handleDescriptionChange}
                                            variant="standard"
                                            InputProps={inputPropsStyle}
                                            inputProps={inputStyle}
                                            disabled={
                                                !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                            }
                                        />
                                    </div>
                                    {/* Original Tax Clearance */}
                                    <div className="newDocument-form-row-item">
                                        <div className="edit-form-row-item-title">{t('OriginalTaxClearance')}</div>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            placeholder={t('OriginalTaxClearance')}
                                            value={addEditableItem.withholdingTax || ''}
                                            onChange={handleChangeOriginalTaxClearance}
                                            variant="standard"
                                            InputProps={inputPropsStyle}
                                            inputProps={inputStyle}
                                            disabled={
                                                !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                            }
                                        />
                                    </div>
                                    {itemEditing && (
                                        <>
                                            {/* Amount */}
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('amount')}</div>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    placeholder={t('amount')}
                                                    value={addEditableItem.amount || ''}
                                                    onChange={(event) =>
                                                        handleAddEditableItemChange('amount', event.target.value)
                                                    }
                                                    variant="standard"
                                                    InputProps={inputPropsStyle}
                                                    inputProps={inputStyle}
                                                    disabled={true}
                                                />
                                            </div>
                                            {/* Confirmation Number */}
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('ConfirmationNumber')}</div>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    placeholder={t('ConfirmationNumber')}
                                                    value={addEditableItem.confirmationNumber || ''}
                                                    onChange={(event) =>
                                                        handleAddEditableItemChange('confirmationNumber', event.target.value)
                                                    }
                                                    variant="standard"
                                                    InputProps={inputPropsStyle}
                                                    inputProps={inputStyle}
                                                    disabled={
                                                        !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                                    }
                                                />
                                            </div>
                                            {/* Credit/Debit Account */}
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">
                                                    {addEditableItem.paymentAcceptanceType === 'תשלום'
                                                        ? t('Debit Account')
                                                        : t('Credit Account')}
                                                </div>
                                                <TextField
                                                    fullWidth
                                                    placeholder={
                                                        addEditableItem.paymentAcceptanceType === 'תשלום'
                                                            ? t('Debit Account')
                                                            : t('Credit Account')
                                                    }
                                                    value={addEditableItem.accountCodeName || ''}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...inputPropsStyle,
                                                        readOnly: true,
                                                        style: {
                                                            backgroundColor: '#f2f2f2',
                                                            ...inputPropsStyle.sx,
                                                        },
                                                    }}
                                                    inputProps={inputStyle}
                                                />
                                            </div>
                                            {/* Reference */}
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">{t('Reference')}</div>
                                                <TextField
                                                    fullWidth
                                                    placeholder={t('Reference')}
                                                    value={addEditableItem.reference || ''}
                                                    onChange={(event) =>
                                                        handleAddEditableItemChange('reference', event.target.value)
                                                    }
                                                    variant="standard"
                                                    InputProps={inputPropsStyle}
                                                    inputProps={inputStyle}
                                                    disabled={
                                                        !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth
                                                    }
                                                />
                                            </div>
                                            {/* Status */}
                                            <div className="newDocument-form-row-item">
                                                <div className="edit-form-row-item-title">
                                                    {t('status')}
                                                    <span className="required-asterisk">*</span>
                                                </div>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-form-control-label-placement"
                                                    name="position"
                                                    defaultValue="top"
                                                    onChange={handleRadioChange}
                                                >
                                                    <FormControlLabel
                                                        value="notDone"
                                                        control={<Radio />}
                                                        label={t('unprocessed')}
                                                        labelPlacement="top"
                                                        checked={!statusCheck}
                                                        disabled={
                                                            !isRange
                                                                ? isClosedReportingMonth
                                                                : addEditableItem.isClosedReportingMonth
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="done"
                                                        control={<Radio />}
                                                        label={t('processed')}
                                                        labelPlacement="top"
                                                        checked={statusCheck}
                                                        disabled={
                                                            !isRange
                                                                ? isClosedReportingMonth
                                                                : addEditableItem.isClosedReportingMonth
                                                        }
                                                    />
                                                </RadioGroup>
                                            </div>
                                        </>
                                    )}
                                    <div
                                        style={{
                                            borderTop: '1px solid black',
                                            margin: '20px 0',
                                            width: '100%',
                                        }}
                                    ></div>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>
                                        {t('Checks')}
                                    </h2>
                                    <div className="PAitems_Container">
                                        {!isClosedReportingMonthAndIsRange && (
                                            <button
                                                className="clientItemButton"
                                                style={{ width: '100%' }}
                                                onClick={() => handleAddItem('checks')}
                                            >
                                                <img
                                                    src={addIcon}
                                                    alt="Income"
                                                    className="clientItem-icon"
                                                />
                                                {t('AddItem')}
                                            </button>
                                        )}
                                        {addEditableItem?.checks?.map((item, index) =>
                                            renderItem('checks', item, index)
                                        )}
                                    </div>
                                    {/* CreditCards Section */}
                                    <div
                                        style={{
                                            borderTop: '1px solid black',
                                            margin: '20px 0',
                                            width: '100%',
                                        }}
                                    ></div>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>
                                        {t('CreditCards')}
                                    </h2>
                                    <div className="PAitems_Container">
                                        {!isClosedReportingMonthAndIsRange && (
                                            <button
                                                className="clientItemButton"
                                                style={{ width: '100%' }}
                                                onClick={() => handleAddItem('creditCards')}
                                            >
                                                <img
                                                    src={addIcon}
                                                    alt="Income"
                                                    className="clientItem-icon"
                                                />
                                                {t('AddItem')}
                                            </button>
                                        )}
                                        {addEditableItem?.creditCards?.map((item, index) =>
                                            renderItem('creditCards', item, index)
                                        )}
                                    </div>
                                    {/* Cash Section */}
                                    <div
                                        style={{
                                            borderTop: '1px solid black',
                                            margin: '20px 0',
                                            width: '100%',
                                        }}
                                    ></div>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>
                                        {t('cash')}
                                    </h2>
                                    <div className="PAitems_Container">
                                        {!isClosedReportingMonthAndIsRange && (
                                            <button
                                                className="clientItemButton"
                                                style={{ width: '100%' }}
                                                onClick={() => handleAddItem('cash')}
                                            >
                                                <img
                                                    src={addIcon}
                                                    alt="Income"
                                                    className="clientItem-icon"
                                                />
                                                {t('AddItem')}
                                            </button>
                                        )}
                                        {addEditableItem?.cash?.map((item, index) =>
                                            renderItem('cash', item, index)
                                        )}
                                    </div>
                                    {/* BankTransfers Section */}
                                    <div
                                        style={{
                                            borderTop: '1px solid black',
                                            margin: '20px 0',
                                            width: '100%',
                                        }}
                                    ></div>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>
                                        {t('BankTransfers')}
                                    </h2>
                                    <div className="PAitems_Container">
                                        {!isClosedReportingMonthAndIsRange && (
                                            <button
                                                className="clientItemButton"
                                                style={{ width: '100%' }}
                                                onClick={() => handleAddItem('bankTransactions')}
                                            >
                                                <img
                                                    src={addIcon}
                                                    alt="Income"
                                                    className="clientItem-icon"
                                                />
                                                {t('AddItem')}
                                            </button>
                                        )}
                                        {addEditableItem?.bankTransactions?.map((item, index) =>
                                            renderItem('bankTransactions', item, index)
                                        )}
                                    </div>
                                </div>
                                <div className="button-container handle" style={{ marginTop: '20px' }}>
                                    <Tooltip title={t('Save')}>
                                        {!(itemEditing && !paymentBeingEdited) &&
                                            !isClosedReportingMonth && (
                                                <IconButton
                                                    onClick={() =>
                                                        itemEditing
                                                            ? handleSave()
                                                            : handleClickDoneCreation()
                                                    }
                                                    disabled={buttonDisabled}
                                                >
                                                    <SaveIcon style={{ color: '#304FFF' }} />
                                                </IconButton>
                                            )}
                                    </Tooltip>
                                    {itemEditing && (
                                        <Tooltip title={t('Delete')}>
                                            <IconButton onClick={() => {/* handle delete */ }}>
                                                <DeleteIcon style={{ color: '#304FFF' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {itemEditing && (
                                        <DocumentTransferDialog
                                            editedItem={addEditableItem}
                                            token={token}
                                            setLoading={setLoading}
                                            RefreshPage={handleTransferRefresh}
                                            t={t}
                                            client={client}
                                        />
                                    )}
                                    <Tooltip title={t('Exit')}>
                                        <IconButton onClick={handleClose}>
                                            <CancelIcon style={{ color: '#304FFF' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>

                        ) : (
                            <>
                                <div
                                    style={{
                                        width: '95%',
                                        height: '80%',
                                        overflowY: 'auto',
                                        padding: '0 20px',
                                    }}
                                    className={`${direction}`}
                                >
                                    <div className="PAitems_Container">
                                        {renderItemsOfType(showTypeType)}
                                    </div>
                                </div>
                                <div className="button-container handle" style={{ marginTop: '20px' }}>
                                    <Tooltip title={t('Save')}>
                                        {!(itemEditing && !paymentBeingEdited) &&
                                            !isClosedReportingMonth && (
                                                <IconButton
                                                    onClick={() =>
                                                        itemEditing
                                                            ? handleSave()
                                                            : handleClickDoneCreation()
                                                    }
                                                    disabled={buttonDisabled}
                                                >
                                                    <SaveIcon style={{ color: '#304FFF' }} />
                                                </IconButton>
                                            )}
                                    </Tooltip>
                                    {itemEditing && (
                                        <Tooltip title={t('Delete')}>
                                            <IconButton onClick={() => {/* handle delete */ }}>
                                                <DeleteIcon style={{ color: '#304FFF' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {itemEditing && (
                                        <DocumentTransferDialog
                                            editedItem={addEditableItem}
                                            token={token}
                                            setLoading={setLoading}
                                            RefreshPage={handleTransferRefresh}
                                            t={t}
                                            client={client}
                                        />
                                    )}
                                    <Tooltip title={t('Exit')}>
                                        <IconButton onClick={handleClose}>
                                            <CancelIcon style={{ color: '#304FFF' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>

                        )}

                    </div>
                </div>
            </Draggable>
        </Portal>
    );
};

export default PaymentAcceptanceCreateEditModal;
